import React from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

const Paginate = styled.div`
  justify-content: center;
  margin: 44px 0;
  display: flex;
  ul {
    width: 100%;
    display: flex;
    li {
      margin-right: 8px;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      outline: 0;
      cursor: pointer;
      display: grid;
      place-items: center;
      border: 1px solid #27750a;
      box-sizing: border-box;
      border-radius: 5px;
      width: 60px;
      height: 45px;
      &.active {
        border-color: #142f0b;
        outline: none !important;
        a {
          color: #142f0b !important;
          &:focus {
            outline: none !important;
          }
        }
      }
      a {
        display: block;
        padding: 0 6px;
        color: rgba(0, 0, 0, 0.65);
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

const Pagination = ({ getFunction, count, pageSize }) => {
  const PAGE_SIZE = pageSize || 10;

  const returnPageCount = (count, pageSize) => {
    if (count <= pageSize) return 1;
    return count % pageSize > 0
      ? Math.floor(count / pageSize) + 1
      : count / pageSize;
  };

  const onChange = (page) => {
    let selectedPage = page.selected;
    getFunction(selectedPage * PAGE_SIZE, PAGE_SIZE);
  };
  return (
    <Paginate>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakClassName={"break-me"}
        pageCount={returnPageCount(count, PAGE_SIZE)}
        onPageChange={onChange}
        activeClassName={"active"}
        pageRangeDisplayed={3}
      />
    </Paginate>
  );
};

export default Pagination;
