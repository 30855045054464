import React from "react";

function Contact() {
  return (
    <div className="content">
      <div className="mx-8 my-8 pb-10 cu">
        <p className="font-bold text-2xl my-8 custom-text-green">Contact Us</p>
        <div className="-mx-4 flex bock blo-ck">
          <div className="w-1/4 full-width full-w-idth ml-r-eset ml-top my-4 mx-4 rounded-lg shadow p-4 bg-white">
            <p className="text-xl text-center custom-text-yellow font-bold">
              General
            </p>
            <p className="text-gray-600 text-lg max-w-sm mx-auto text-center my-4">
              General Enquiries on products and services.
            </p>
            <div className="text-center py-6">
              <a
                href="/contact/us"
                className="py-3 px-6 custom-bg-green rounded-lg text-white font-bold mx-auto"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="w-1/4 full-width full-w-idth ml-r-eset ml-top mx-4 my-4 rounded-lg shadow p-4 bg-white">
            <p className="text-xl text-center custom-text-yellow font-bold">
              Sales
            </p>
            <p className="text-gray-600 text-lg max-w-sm mx-auto text-center my-4">
              For Subscription and Sales Enquiries
            </p>
            <div className="text-center py-6">
              <a
                href="/contact/sales"
                className="py-3 px-6 custom-bg-green rounded-lg text-white font-bold mx-auto"
              >
                Contact Sales
              </a>
            </div>
          </div>
          <div className="w-1/4 full-width full-w-idth ml-r-eset ml-top mx-4 my-4 rounded-lg shadow p-4 bg-white">
            <p className="text-xl text-center custom-text-yellow font-bold">
              Support
            </p>
            <p className="text-gray-600 text-lg max-w-sm mx-auto text-center my-4">
              For Technical Enquiries and Support
            </p>
            <div className="text-center py-6">
              <a
                href="/contact/tech"
                className="py-3 px-6 custom-bg-green rounded-lg text-white font-bold mx-auto"
              >
                Contact Support
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-8 my-16">
        <p className="font-bold text-2xl mt-8 custom-text-green">Contact Us</p>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Lagos (HQ) - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                10, Evelyn Owuama St
              </p>
              <p className="text-gray-600 text-lg font-light">
                River Valley Estate, Ojodu
              </p>
              <p className="text-gray-600 text-lg font-light">Ikeja, Lagos</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0700-976-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0704-044-6699
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Abuja - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                Bataiya Plaza Suite 11
              </p>
              <p className="text-gray-600 text-lg font-light">Area 11 Garki,</p>
              <p className="text-gray-600 text-lg font-light">Garki, Abuja</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                080-350-41712
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Akure - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                3rd Floor, Olukayode House
              </p>
              <p className="text-gray-600 text-lg font-light">
                Oluwatuyi Street
              </p>
              <p className="text-gray-600 text-lg font-light">Akure, Ondo</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0706-196-1080
              </p>
            </div>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
            Benin - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                Okun Aihie & Co
              </p>
              <p className="text-gray-600 text-lg font-light">
                22, Dawson Road,
              </p>
              <p className="text-gray-600 text-lg font-light">Wisdom Building Benin</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0803-444-7396
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Enugu - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                High Court of Justice
              </p>
              <p className="text-gray-600 text-lg font-light">State High Court</p>
              <p className="text-gray-600 text-lg font-light">Complex, Enugu</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0806-039-8178
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Ibadan - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                Shop 30, Golden Plaza
              </p>
              <p className="text-gray-600 text-lg font-light">
                Complex, Kumapoyi Str
              </p>
              <p className="text-gray-600 text-lg font-light">Iyana Agbala, Iwo Rd</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0806-840-0479
              </p>
            </div>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              Ilorin - MAP <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">
                High Court of Justice
              </p>
              <p className="text-gray-600 text-lg font-light">
                State High Court
              </p>
              <p className="text-gray-600 text-lg font-light">Ilorin</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0803-576-0619
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
            Port Harcourt - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">Port Harcourt City</p>
              <p className="text-gray-600 text-lg font-light">Complex, Enugu</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0803-925-3455
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              ASABA - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">C/O Chief Ogedazie Chambers</p>
              <p className="text-gray-600 text-lg font-light">Nnebisi Road, Asaba</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0803-429-4814
              </p>
            </div>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
              ABA - MAP <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">C/O WRN</p>
              <p className="text-gray-600 text-lg font-light">Aba</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0802-376-4891
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            <p className="font-bold text-lg my-4 custom-text-green">
            CALABAR - <span className="custom-text-yellow">MAP</span>
            </p>
            <div className="my-4">
              <p className="text-gray-600 text-lg font-light">High Court of Justice</p>
              <p className="text-gray-600 text-lg font-light">Book Stand</p>
              <p className="text-gray-600 text-lg font-light">Calabar</p>
            </div>
            <div className="my-4">
              <p className="custom-text-green text-lg font-bold">
                0700-WRN-0000
              </p>
              <p className="custom-text-green text-lg font-bold">
                0703-349-1298
              </p>
            </div>
          </div>
          <div className="p-4 w-1/3 full-width">
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
