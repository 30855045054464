import { case_path } from "./constants";

export function openSummaryIfNoAuthentication_GoToDashboardIfAuth(
  history,
  auth,
  token,
  court_id,
  id
) {
  if (auth)
    window.open(
      `${process.env.REACT_APP_DBOARD_URL_}/login/${token}?redirectUrl=${case_path}.${court_id}.${id}`,
      "_blank"
    );
  else history.push(`/judgement/${court_id}/${id}`);
}
