import React, { createContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
// import notify from "utils/toast";
import GET_JUDGEMENT_LIST from "schema/judgement_list";
import { judgement_list_pagination } from "utils/constants";

export const HomeContext = createContext({});

// Searches + JL + Blogs(web_display)

export default function HomeContextProvider({ children }) {
  // STATE
  const [judgementListMostRecent, setJudgementListRecent] = React.useState({
    sc: [],
    ca: [],
  });
  // STATE

  // QUERIES + LAZY
  const [getJL, { loading: jlLoading, data: jlData }] = useLazyQuery(
    GET_JUDGEMENT_LIST,
    {
      onCompleted({ judgements_list: { data } }) {
        if (data.length && data[0].court_id === 2) {
          getJL({
            variables: {
              court_category: 1,
              region: "ng",
              recent: {
                pagination: judgement_list_pagination,
                last_date: Math.round(new Date(Date.now()).getTime() / 1000),
              },
            },
          });
        }
      },
    }
  );

  // QUERIES

  useEffect(() => {
    if (jlData) {
      const {
        judgements_list: { data },
      } = jlData;

      if (data.length) {
        if (data[0].court_id === 1) {
          setJudgementListRecent((others) => ({
            ...others,
            ca: data,
          }));
        } else {
          // court_id == 2
          setJudgementListRecent((others) => ({
            ...others,
            sc: data,
          }));
        }
      }
    }
  }, [jlData]);

  return (
    <HomeContext.Provider value={{ judgementListMostRecent, getJL, jlLoading }}>
      {children}
    </HomeContext.Provider>
  );
}
