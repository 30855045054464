import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function TopFaqs() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
        <div className="mb-8">
          <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
            <img style={{ marginRight: '5px' }} src={arreft} />
            Back To Support
          </Link>
        </div>
        <p className="custom-text-green text-2xl font-semibold mb-4">Top FAQs</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How many versions of WRN are there?" triggerTagName="div">
                <p className="text-sm font-semibold">There are three versions of WRN;</p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Online:</b>The web version of weekly Reports of Nigeria. WRN Online is the standard default WRN version. It is accessible via a web browser. It can also be viewed on mobile browsers. <a href="/features" className="underline font-medium">Subscribe to WRN Online</a></p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Offline:</b>This is the offline version of Weekly Reports of Nigeria. This version is installed on desktops or laptops. Once the Offline version is installed, the customer gets access to cases, judgment, constitution and other related content.</p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Mobile:</b>This is the mobile version of Weekly Reports of Nigeria. To access this version, you need to download it from the google play store and the apple istore to</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How much does it cost to subscribe to WRN?" triggerTagName="div">
                <p className="text-md my-4">See all subscription prices for WRN on the subscription page.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Which WRN Version is right for me?" triggerTagName="div">
                <p className="text-md my-4">See all the available <a href="/features" className="underline font-medium">WRN versions.</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Do I have to pay for all the versions of WRN?" triggerTagName="div">
                <p className="text-md my-4">You are not required to pay for all the versions of WRN.</p>
                <p className="text-md my-4">If you subscribe for WRN OFFLINE (Offline Version), you get WRN Online and WRN Mobile for free.</p>
                <p className="text-md my-4">If you subscribe for WRN Online (Web Version), you get WRN Mobile for Free.</p>
                <p className="text-md my-4">If you subscribe for WRN Mobile, you get WRN Online (Web Version for free)</p>
                <p className="text-md my-4">Also, depending on your subscription category (Lawyer, Student, Law Student), you get WRN Mobile for free.</p>
                <p className="text-md my-4">Take advantage today and <a href="/features" className="underline font-medium">subscribe</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I am interested in discounts and special offers?" triggerTagName="div">
                <p className="text-md my-4">View discounts and special offers for <a href="/features" className="underline font-medium">WRN Subscription</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Do I need the internet to use WRN?" triggerTagName="div">
                <p className="text-md my-4">You do not require the internet to use all the versions of WRN. Our offline version allows you to have WRN installed locally on your laptop or desktop. All you have to do is subscribe for WRN offline and download the application from our website.</p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium">View WRN Offline subscription here</a></p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Download WRN Offline</a></p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFaqs;
