import React from 'react'
import icons from "assets";
import { BrowserRouter } from 'react-router-dom';

export default function Article() {
    return (
        <div className="content">
            <div className="m-8">
                <div className="max-w-6xl mx-auto">
                    <img alt={"image"} src={icons.nanda} className="w-full mx-auto rounded-lg" />
                </div>
                <div className="my-10 max-w-5xl mx-auto ">
                    <div className="mb-10">
                        <p className="text-black text-5xl font-bold">Title of the page</p>
                        <div className="mt-6 flex items-center">
                            <div>
                                <span className="py-2 px-4 bg-gray-100 rounded-lg font-semibold">#Law</span>
                            </div>
                            <p className="ml-6 text-gray-500 font-medium">2hrs ago</p>
                            <p className="ml-6 text-gray-500 font-medium">5 mins read</p>
                        </div>
                    </div>
                    <p className="text-justify text-lg">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                        Morbi non aliquam tempus ut adipiscing elementum 
                        malesuada eget non. Pharetra odio adipiscing euismod 
                        pretium quam leo bibendum est, euismod. Urna potenti 
                        varius iaculis at est, nisi, nunc vulputate consectetur. 
                        Amet molestie metus nullam amet viverra. Mauris 
                        tortor, bibendum posuere pellentesque porttitor. Et felis 
                        sit nulla cras aliquet.
                        Dolor vulputate donec mattis et faucibus. Nibh habitant 
                        quisque nibh adipiscing ultrices maecenas ac. Quis 
                        euismod purus, ut lacus, pellentesque hac. Faucibus eu 
                        vitae sagittis pulvinar sed enim fermentum. 
                        Senectus eget sem scelerisque enim tristique erat 
                        porttitor lacus, amet. Odio nisi, eget ut ullamcorper id 
                        dui aliquet. Accumsan libero commodo est sit. Ac 
                        viverra nunc elementum odio lacus, scelerisque pulvina
                        congue lacinia. Id vehicula condimentum bibendum 
                        arcu vitae gravida. 
                        Cursus maecenas purus gravida adipiscing bibendum 
                        proin. At pharetra sed vitae malesuada purus 
                        scelerisque iaculis consectetur. Tellus molestie id mattis 
                        interdum et sed. Proin semper lectus hac cras senectus 
                        mauris tellus. Egestas lacus nec eu faucibus elementum. 
                    </p>
                </div>
                <div className="max-w-5xl my-4 mx-auto">
                        <div className="flex blo-ck items-center mt-4">
                            <div className="flex items-center">
                                <a className="flex items-center" href="">
                                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.3456 9.65647C19.2754 8.92447 18.6041 7.94572 18.0675 6.43859C17.868 5.87834 17.3704 5.51609 16.7996 5.51609C16.1861 5.51609 15.6251 5.91172 15.2606 6.60134C15.056 6.98234 14.9302 7.40066 14.8909 7.83134C14.5129 7.14209 13.7449 6.99547 13.3807 7.48559C13.3807 7.48559 10.386 5.20897 9.36449 4.48409C8.00699 3.52184 6.20887 4.58759 6.25387 5.68822C4.53974 4.75897 2.65799 6.39959 3.23774 8.11897C2.57474 7.99747 0.878994 9.75509 2.66174 11.1051C1.42687 11.2318 0.947244 13.1653 2.38237 14.0455C2.38237 14.0455 2.38912 14.3128 2.40824 14.4441C1.46249 14.7313 1.13624 16.5193 2.05087 17.0837C2.05087 17.0837 8.64712 21.2957 10.9177 22.5628C12.1515 23.2513 14.2759 23.5217 16.1685 22.9071C18.0064 22.7166 19.5656 21.7423 20.8185 20.0061C24.2666 15.2278 21.5351 10.4702 20.3456 9.65647ZM19.9417 19.5403C18.5059 21.5578 16.4437 21.7671 14.8657 21.7671C12.2899 21.7671 10.7164 20.5322 10.7164 20.5322L3.64687 15.2537C2.30849 14.3297 3.11437 12.8575 4.05337 12.8845L9.67724 16.7166L10.3695 16.7597C10.3695 16.7597 5.34637 13.1537 3.71999 11.8843C2.77049 11.1433 3.08249 9.72809 4.31174 9.44684L11.3452 14.4958L12.0367 14.539L4.52362 8.71222C2.93624 7.55197 4.52924 5.36422 6.06824 6.47834C7.84312 7.76309 13.1827 11.8352 13.1827 11.8352L13.875 11.8787L6.84562 6.05609C6.78487 5.15759 7.97999 4.37647 8.93549 5.09122C10.857 6.52859 16.6245 10.8362 16.6245 10.8362C16.476 10.4203 16.4017 10.1076 16.4017 10.1076C16.4017 10.1076 15.306 8.28584 15.8996 6.95947C16.4209 5.79622 17.4754 5.98522 17.688 6.61034C18.2531 8.27459 18.8385 9.26234 20.0096 10.0948C21.5051 11.158 23.0344 15.1967 19.9417 19.5403Z" fill="#CEBF09"/>
                                        <path d="M17.2654 1.08337L14.0366 0.75L15.1384 5.3475L17.2654 1.08337Z" fill="#CEBF09"/>
                                        <path d="M19.9365 1.97412L18.2959 5.7965L22.1228 4.77125L19.9365 1.97412Z" fill="#CEBF09"/>
                                        <path d="M10.9166 1.33105L8.58789 3.25593L11.9419 5.44143L10.9166 1.33105Z" fill="#CEBF09"/>
                                    </svg>
                                </a>
                                <a className="flex items-center ml-8" href="">
                                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                        <path d="M12.0012 1.5C5.37303 1.5 0.00115788 5.86406 0.00115788 11.25C0.00115788 13.4812 0.93397 15.525 2.48085 17.1703C1.78241 19.0172 0.329283 20.5828 0.305845 20.6016C-0.00352962 20.9297 -0.0879046 21.4078 0.0902204 21.8203C0.268345 22.2328 0.676158 22.5 1.12616 22.5C4.00897 22.5 6.28241 21.2953 7.64647 20.3297C9.00116 20.7563 10.4637 21 12.0012 21C18.6293 21 24.0012 16.6359 24.0012 11.25C24.0012 5.86406 18.6293 1.5 12.0012 1.5ZM12.0012 18.75C10.7496 18.75 9.5121 18.5578 8.32616 18.1828L7.2621 17.8453L6.34803 18.4922C5.67772 18.9656 4.75897 19.4953 3.65272 19.8516C3.99491 19.2844 4.32772 18.6469 4.58553 17.9672L5.08241 16.65L4.11678 15.6281C3.26835 14.7234 2.25116 13.2281 2.25116 11.25C2.25116 7.11563 6.6246 3.75 12.0012 3.75C17.3777 3.75 21.7512 7.11563 21.7512 11.25C21.7512 15.3844 17.3777 18.75 12.0012 18.75Z" fill="#CEBF09"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0">
                                        <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="ml-3 font-medium">15 Comments</p>
                                </a>
                            </div>
                            <div className="flex sec-row items-center">
                                <a className="flex items-center mx-2" href=""></a>
                            </div>
                        </div>
                        <form className="flex items-center relative mt-5">
                            <input className="pr-32 pl-4 py-4 border border-gray-200 rounded-lg w-full" placeholder="Enter Comment" />
                            <button className="py-2 px-6 custom-bg-green text-white font-semibold rounded-lg absolute right-0 mx-4">Submit</button>
                        </form>
                    <hr className="bg-gray-200 my-16"/>
                    <p className="text-xl font-bold">Featured Articles</p>
                    <div className="mt-4 mb-10 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 grid gap-4">
                        <div className="border border-gray-200 rounded-lg">
                            <img alt={"image"} src={icons.nanda} className="w-full mx-auto rounded-t-lg" />
                            <div className="p-4">
                                <p className="text-black text-lg font-bold">Title of the page</p>
                                <div className="mt-4">
                                    <span className="py-2 px-4 bg-gray-100 rounded-lg font-semibold text-sm">#Law</span>
                                </div>
                                <div className="mt-4 flex items-center">
                                    <p className="text-gray-500 font-medium text-sm">2hrs ago</p>
                                    <p className="ml-6 text-gray-500 font-medium text-sm">5 mins read</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-gray-200 rounded-lg">
                            <img alt={"image"} src={icons.nanda} className="w-full mx-auto rounded-t-lg" />
                            <div className="p-4">
                                <p className="text-black text-lg font-bold">Title of the page</p>
                                <div className="mt-4">
                                    <span className="py-2 px-4 bg-gray-100 rounded-lg font-semibold text-sm">#Law</span>
                                </div>
                                <div className="mt-4 flex items-center">
                                    <p className="text-gray-500 font-medium text-sm">2hrs ago</p>
                                    <p className="ml-6 text-gray-500 font-medium text-sm">5 mins read</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-gray-200 rounded-lg">
                            <img alt={"image"} src={icons.nanda} className="w-full mx-auto rounded-t-lg" />
                            <div className="p-4">
                                <p className="text-black text-lg font-bold">Title of the page</p>
                                <div className="mt-4">
                                    <span className="py-2 px-4 bg-gray-100 rounded-lg font-semibold text-sm">#Law</span>
                                </div>
                                <div className="mt-4 flex items-center">
                                    <p className="text-gray-500 font-medium text-sm">2hrs ago</p>
                                    <p className="ml-6 text-gray-500 font-medium text-sm">5 mins read</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-gray-200 rounded-lg">
                            <img alt={"image"} src={icons.nanda} className="w-full mx-auto rounded-t-lg" />
                            <div className="p-4">
                                <p className="text-black text-lg font-bold">Title of the page</p>
                                <div className="mt-4">
                                    <span className="py-2 px-4 bg-gray-100 rounded-lg font-semibold text-sm">#Law</span>
                                </div>
                                <div className="mt-4 flex items-center">
                                    <p className="text-gray-500 font-medium text-sm">2hrs ago</p>
                                    <p className="ml-6 text-gray-500 font-medium text-sm">5 mins read</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
