import React, { useContext, useEffect } from "react";
import caseicon from "assets/case-icon.svg";
import moment from "moment";
import { CaseContext } from "context/Cases";
import { BlockLoader } from "components/Loaders";
import stripHtml from "utils/strip-html";
import { openSummaryIfNoAuthentication_GoToDashboardIfAuth } from "utils/summary";
import { AuthContext } from "context/auth";
import Pagination from "components/Pagination";
// import EmptyState from "components/EmptyState";

export default function Cases({ match, history }) {
  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  const pageSize = 12;
  const { getJudgementList, loading, data } = React.useContext(CaseContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [count, setCount] = React.useState(0);
  const court = match?.params?.court;
  const court_id = court === "sc" ? 2 : 1;

  useEffect(
    () =>
      getJudgementList({
        variables: {
          court_category: court_id,
          region: "ng",
          options: {
            skip: 0,
            take: pageSize,
            order: {
              key: "volume",
              value: -1,
            },
          },
        },
      }),
    []
  );

  useEffect(() => {
    if (data?.judgements_list?.total_count) {
      if (data.judgements_list.total_count !== count) {
        setCount(data.judgements_list.total_count);
      }
    } else setCount(0);
  }, [data]);

  return (
    <div className="content">
      <div className="mx-8 my-16">
        <p className="text-2xl font-bold mb-8">
          {court_id === 1 ? "Court of Appeal" : "Supreme Court"} Cases
        </p>
        {loading && <BlockLoader />}
        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4">
          {data?.judgements_list.data?.map(
            ({ case_id, party_1, party_2, case_no, date, description }) => {
              const striped = stripHtml(description);

              return (
                <div className="bg-gray-50 rounded-lg p-4 flex" key={case_id}>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      openSummaryIfNoAuthentication_GoToDashboardIfAuth(
                        history,
                        isAuthenticated,
                        token,
                        court_id,
                        case_id
                      )
                    }
                  >
                    <p
                      className="case-title-case font-semibold text-xl"
                      id="title"
                    >
                      {party_1}
                      <span className="yellow-vs mx-2 font-semibold">vs</span>
                      {party_2}
                    </p>
                    <p className="italic mt-1 text-sm">({case_no})</p>
                    <p className="my-5">
                      Facts and History <br />
                      {striped.length <= 200
                        ? striped
                        : striped.substring(0, 200)}{" "}
                      ...
                      <br />
                      <span className="cr-link">continue reading</span>
                    </p>
                    <p
                      className="italic text-sm font-bold"
                      style={{ color: "#cebf09" }}
                    >
                      {moment(date * 1000).format("Do MMM, YYYY")}
                    </p>
                  </div>
                </div>
              );
            }
          )}
        </div>
        {count ? (
          <Pagination
            getFunction={(skip, take_) => {
              window.scrollTo(0, 0); // Scroll to Top ^_^
              getJudgementList({
                variables: {
                  court_category: court_id,
                  region: "ng",
                  options: {
                    skip,
                    take: take_,
                    order: {
                      key: "volume",
                      value: -1,
                    },
                  },
                },
              });
            }}
            count={count}
            pageSize={pageSize}
          />
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
}
