import React from "react";
import search from "assets/search.svg";
import arr from "assets/arrow-right.svg";
import { Link } from "react-router-dom";

function Support() {
  return (
    <div className="content">
      <div className="mx-8 my-8">
        <p className="font-bold text-2xl text-center custom-text-green">Support</p>
        <p className="text-gray-600 text-lg text-center mt-4 mb-6">
          If you have any question, kindly check FAQ section
        </p>
      </div>
      <div className="mx-8 my-6">
        <div className="flex blo-ck -mx-4 my-4">
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/topfaqs">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                TOP FAQs
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/accountsettings">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Account Settings
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/registration">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Registration & Login
              </div>
            </Link>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/subscription">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Subscription & Billing
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/payments">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Payments
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/commissions">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Commissions
              </div>
            </Link>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/orders">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Orders
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/store">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Store & Books
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/versions">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                WRN Versions
              </div>
            </Link>
          </div>
        </div>
        <div className="flex blo-ck -mx-4 my-4">
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/downloads">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Downloads
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/blog">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Blog & Archives
              </div>
            </Link>
          </div>
          <div className="w-1/3 full-width ml-reset mx-4 my-4 p-4">
            <Link to="/support/workwithus">
              <div className="w-full bg-white py-3 font-bold custom-text-green border text-center custom-border-yellow">
                Work With Us
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-8 bg-white py-8 px-4">
        <p className="text-2xl font-bold text-yellow-600 text-center">
          Can’t Find Answers?
        </p>
        <p className="font-semibold text-black my-2 text-center">
          Not to worry, we are here for you.
        </p>
        <div className="text-center pt-4">
          <Link to="/contact" className="py-2 px-8 custom-text-green justify-center flex items-center font-bold text-xl">
            Contact Us <img src={arr} className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Support;
