import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Orders() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Orders & Delivery</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I place an order for WRN products and Law books?" triggerTagName="div">
                <p className="text-md my-4">You may place an order for WRN hard copies, as well as other Law Books.</p>
                <p className="text-md my-4"><a href="/store" className="underline font-medium">Visit WRN Store</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I have bought a book on WRN, can I read it on the application?" triggerTagName="div">
                <p className="text-md my-4">You can add your purchased books to your WRN account. This implies that you would be able to read the books from your WRN dashboard.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What is the maximum order I can make?" triggerTagName="div">
                <p className="text-md my-4">You can place order for as many books you want. Please, contact us for bulk purchases.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How long does it take for my order to be delivered to me?" triggerTagName="div">
                <p className="text-md my-4">Your order should get to you between 4 to 10 days. Please note that delivery times may vary based on a number of factors. <a href="/contact" className="underline font-medium">Contact Us</a> for questions on delivery time. You can also call us on 0700-WRN-0000.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Are there delivery charges on orders?" triggerTagName="div">
                <p className="text-md my-4">Yes, we charge a small amount to cover delivery costs. At the point of order, you would be notified of the delivery charges.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Why have I not received the order I made for books?" triggerTagName="div">
                <p className="text-md my-4">Please, <a href="/contact" className="underline font-medium">contact Us</a> if you have made an order, and have not received your order within 10 days.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
