import React from "react";
import fifth from "assets/fifth.png";
import search from "assets/search.svg";
import Book from "assets/Book1.png";
import cart from "assets/cart.svg";
import { Link } from "react-router-dom";

function Store() {
  return (
    <div className="content">
      <div className="bg-white mx-8 flex items-center blo-ck my-8">
        <div className="my-10 w-1/2 full-width">
          <p className="custom-text-green mb-10 font-bold max-w-lg text-2xl my-4">
            Buy Hardcopy Books at Discounted Prices
          </p>
          <p className="text-black max-w-lg my-4">
            Order a book or WRN Edition from our online store and get it
            delivered anywhere in Nigeria
          </p>
          <div className="my-8">
            <form className="max-w-lg flex">
              <input
                type="text"
                className="w-full rounded-lg py-4 px-4 border-2 border-yellow-600"
                placeholder="Search for law books"
              />
              <button className="py-4 px-6 custom-bg-green rounded-r-lg rounded-l-none -ml-2">
                <img src={search} />
              </button>
            </form>
          </div>
        </div>
        <div className="w-1/2 my-10 full-width fifth">
          <img className="mx-auto" src={fifth} />
        </div>
      </div>
      <div className="bg-gray-100 mx-8 flex blo-ck my-8 p-6">
        <div className="w-1/4 full-width">
          <div>
            <p className="p-2 border-b-2 mb-2 border-gray-200 font-bold custom-text-yellow">
              Book by Genre
            </p>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Human Rights
              </a>
            </div>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Economic Rights
              </a>
            </div>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Financial Law
              </a>
            </div>
          </div>
          <div className="mt-20">
            <p className="p-2 border-b-2 mb-2 border-gray-200 font-bold custom-text-yellow">
              Recommendations
            </p>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Book of the Month
              </a>
            </div>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Trending
              </a>
            </div>
            <div className="p-2 mb-2 w-full">
              <a href="" className="text-gray-700">
                Favorites
              </a>
            </div>
          </div>
        </div>
        <div className="w-full ml-6 ml-reset p-6 bg-white rounded-lg mx-auto">
          <div className="flex items-center mb-8">
            <p className="font-bold text-xl text-green-800">Featured</p>
            <a
              href=""
              className="font-bold text-sm custom-text-yellow underline ml-auto"
            >
              View All >
            </a>
          </div>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 grid gap-4">
            <div className="flex my-6">
              <Link className="book" to="/store/Item">
                <img className="w-full" src={Book} />
              </Link>
              <div className="mx-4 top">
                <Link
                  to="/store/Item"
                  className="max-w-sm custom-text-green font-bold"
                >
                  Nigerian Human Rights Law Reports
                </Link>
                <p className="text-xs max-w-sm custom-text-yellow my-4 font-bold">
                  By Femi Falana SAN
                </p>
                <p className="text-sm max-w-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.LoremLorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
                <div className="flex items-center my-6">
                  <p className="text-sm custom-text-yellow font-bold">
                    NGN 1,500
                  </p>
                  <img className="ml-auto" src={cart} />
                </div>
              </div>
            </div>
            <div className="flex my-6">
              <Link className="book" to="/store/Item">
                <img className="w-full" src={Book} />
              </Link>
              <div className="mx-4 top">
                <Link
                  to="/store/Item"
                  className="max-w-sm custom-text-green font-bold"
                >
                  Nigerian Human Rights Law Reports
                </Link>
                <p className="text-xs max-w-sm custom-text-yellow my-4 font-bold">
                  By Femi Falana SAN
                </p>
                <p className="text-sm max-w-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.LoremLorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
                <div className="flex items-center my-6">
                  <p className="text-sm custom-text-yellow font-bold">
                    NGN 1,500
                  </p>
                  <img className="ml-auto" src={cart} />
                </div>
              </div>
            </div>
            <div className="flex my-6">
              <Link className="book" to="/store/Item">
                <img className="w-full" src={Book} />
              </Link>
              <div className="mx-4 top">
                <Link
                  to="/store/Item"
                  className="max-w-sm custom-text-green font-bold"
                >
                  Nigerian Human Rights Law Reports
                </Link>
                <p className="text-xs max-w-sm custom-text-yellow my-4 font-bold">
                  By Femi Falana SAN
                </p>
                <p className="text-sm max-w-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.LoremLorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
                <div className="flex items-center my-6">
                  <p className="text-sm custom-text-yellow font-bold">
                    NGN 1,500
                  </p>
                  <img className="ml-auto" src={cart} />
                </div>
              </div>
            </div>
            <div className="flex my-6">
              <Link className="book" to="/store/Item">
                <img className="w-full" src={Book} />
              </Link>
              <div className="mx-4 top">
                <Link
                  to="/store/Item"
                  className="max-w-sm custom-text-green font-bold"
                >
                  Nigerian Human Rights Law Reports
                </Link>
                <p className="text-xs max-w-sm custom-text-yellow my-4 font-bold">
                  By Femi Falana SAN
                </p>
                <p className="text-sm max-w-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit.LoremLorem ipsum dolor sit amet, consectetur adipiscing
                  elit.
                </p>
                <div className="flex items-center my-6">
                  <p className="text-sm custom-text-yellow font-bold">
                    NGN 1,500
                  </p>
                  <img className="ml-auto" src={cart} />
                </div>
              </div>
            </div>
            
          </div>
          <p className="font-bold text-xl my-10 text-green-800">Trending</p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 grid gap-4">
            <div className="my-6">
              <div>
                <img className="" src={Book} />
              </div>
              <div className="my-6">
                <a href="" className="max-w-sm custom-text-green font-bold">
                  Nigerian Human Rights Law Reports
                </a>
                <p className="text-sm custom-text-yellow my-2 font-bold">
                  NGN 1,500
                </p>
              </div>
            </div>
            <div className="my-6">
              <div>
                <img className="" src={Book} />
              </div>
              <div className="my-6">
                <a href="" className="max-w-sm custom-text-green font-bold">
                  Nigerian Human Rights Law Reports
                </a>
                <p className="text-sm custom-text-yellow my-2 font-bold">
                  NGN 1,500
                </p>
              </div>
            </div>
            <div className="my-6">
              <div>
                <img className="" src={Book} />
              </div>
              <div className="my-6">
                <a href="" className="max-w-sm custom-text-green font-bold">
                  Nigerian Human Rights Law Reports
                </a>
                <p className="text-sm custom-text-yellow my-2 font-bold">
                  NGN 1,500
                </p>
              </div>
            </div>
            <div className="my-6">
              <div>
                <img className="" src={Book} />
              </div>
              <div className="my-6">
                <a href="" className="max-w-sm custom-text-green font-bold">
                  Nigerian Human Rights Law Reports
                </a>
                <p className="text-sm custom-text-yellow my-2 font-bold">
                  NGN 1,500
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
