import React from "react";
import Book from "assets/Book1.png";

function Item() {
  return (
    <div className="content">
      <div className="p-4 rounded-lg bg-yellow-100 blo-ck flex items-center my-10 mx-8">
        <button className="py-3 px-6 custom-bg-green text-white font-bold">
          View cart
        </button>
        <p className="font-lg mx-4 ml-reset text-black top-2">
          <b className="custom-text-green mr-1">
            "Nigerian Human Rights Law Reports"
          </b>{" "}
          has been added to your cart.
        </p>
      </div>
      <div className="flex items-center my-8 mx-8 none">
        <a href="" className="text-gray-500 font-medium text-sm">
          Store >
        </a>
        <p className="text-gray-800 font-medium text-sm mx-2">
          Nigerian Human Rights Law Reports
        </p>
      </div>
      <div className="flex blo-ck my-16 mx-8">
        <div>
          <img className="center" src={Book} />
        </div>
        <div className="ml-8 full-width ml-reset top">
          <p className="text-2xl custom-text-green font-bold">
            Nigerian Human Rights Law Reports
          </p>
          <p className="text-sm custom-text-yellow my-4 font-bold">
            By Femi Falana SAN
          </p>
          <p className="text-2xl custom-text-yellow font-bold">NGN 1,500</p>
          <p className="text-lg max-w-3xl my-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <p className="p-2 border-b-2 mb-2 border-gray-200 text-gray-800 font-bold">
            Category: <b className="custom-text-green">Human Rights</b>
          </p>
          <div className="flex items-center">
            <div className="flex my-4">
              <button className="py-3 px-4 bg-white border border-gray-200">
                -
              </button>
              <p className="py-3 px-4 bg-white border border-gray-200">1</p>
              <button className="py-3 px-4 bg-white border border-gray-200">
                +
              </button>
            </div>
            <button className="py-3 px-6 custom-bg-green text-white font-bold ml-auto">
              Add to cart
            </button>
          </div>
        </div>
      </div>
      <div className="py-4 px-6 rounded-lg mx-8 my-16">
        <div className="flex items-center mb-8">
          <p className="font-bold text-xl text-green-800">Featured</p>
          <a href="" className="font-bold text-sm custom-text-yellow ml-auto">
            View All >
          </a>
        </div>
        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 grid gap-4">
          <div className="my-6">
            <div>
              <img className="" src={Book} />
            </div>
            <div className="my-6">
              <a href="" className="max-w-sm custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </a>
              <p className="text-sm custom-text-yellow my-2 font-bold">
                NGN 1,500
              </p>
            </div>
          </div>
          <div className="my-6">
            <div>
              <img className="" src={Book} />
            </div>
            <div className="my-6">
              <a href="" className="max-w-sm custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </a>
              <p className="text-sm custom-text-yellow my-2 font-bold">
                NGN 1,500
              </p>
            </div>
          </div>
          <div className="my-6">
            <div>
              <img className="" src={Book} />
            </div>
            <div className="my-6">
              <a href="" className="max-w-sm custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </a>
              <p className="text-sm custom-text-yellow my-2 font-bold">
                NGN 1,500
              </p>
            </div>
          </div>
          <div className="my-6">
            <div>
              <img className="" src={Book} />
            </div>
            <div className="my-6">
              <a href="" className="max-w-sm custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </a>
              <p className="text-sm custom-text-yellow my-2 font-bold">
                NGN 1,500
              </p>
            </div>
          </div>
          <div className="my-6">
            <div>
              <img className="" src={Book} />
            </div>
            <div className="my-6">
              <a href="" className="max-w-sm custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </a>
              <p className="text-sm custom-text-yellow my-2 font-bold">
                NGN 1,500
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Item;
