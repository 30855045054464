import React from "react";
import { Link } from "react-router-dom";
import { GridEqual } from "components/Styles";
import { registerSchema } from "utils/validation";
import { useFormik } from "formik";
import { AuthContext } from "context/auth";
import { ButtonLoader } from "components/Loaders";
import styled from "styled-components";
import Checkbox from "components/form/Checkbox";
import Input from "components/form/Input";
import law from "assets/img/law.jpg";
import logo from "assets/img/logo.svg";
import "assets/css/login.css";
import history from "utils/history";

const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
`;

const Content = styled.div`
    padding: 50px;
    h3 {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }
    & > p {
        font-size: 0.95rem;
        margin-bottom: 20px;
    }
`;

const Container = styled.div`
    max-width: 960px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    background: white;
    border-radius: 10px;
    margin: 40px;
    @media (max-width: 960px) {
        display: block;
        margin: 0px;
    }
`;

const Img = styled.img`
    display: block;
    margin-bottom: 30px;
`;
const ImgStyle = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (max-width: 960px) {
        display: none;
    }
`;

const Register = () => {
    const r = new URLSearchParams(history.location.search).get("role");
    const plan = new URLSearchParams(history.location.search).get("plan_code");
    const spc = new URLSearchParams(history.location.search).get("spc");
    const [planRole, setPlanRole] = React.useState(null);

    const {
        registerLoading: loading,
        register,
        errors: regErrors,
    } = React.useContext(AuthContext);
    const { handleSubmit, handleChange, values, errors, setFieldValue } =
        useFormik({
            initialValues: {
                first_name: "",
                last_name: "",
                phone_number: "",
                password: "",
                role: "",
                sex: "N", // None specified [M|F|N]
                contactAddress: "",
                email: "",
                confirmPassword: "",
                refferal_code: "SPIDEL",
            },
            validationSchema: registerSchema,
            onSubmit() {
                register({
                    variables: {
                        ...values,
                        role: parseInt(values?.role),
                        region: "ng",
                    },
                });
            },
        });

    React.useEffect(() => {
        if (r) {
            setPlanRole(parseInt(r));
            setFieldValue("role", parseInt(r));
        }
    }, [r]);

    return (
        <Wrapper>
            <Container>
                <Content>
                    <Img src={logo} alt="logo" />
                    <h3>Sign Up !</h3>
                    <p>
                        Create an account to have access to thousands of Weekly
                        Cases.
                    </p>
                    <form className="auth-form" onSubmit={handleSubmit}>
                        <Checkbox
                            options={[
                                {
                                    label: "Lawyer",
                                    value: 2,
                                    defaultCheck: planRole === 2,
                                },
                                {
                                    label: "Law School",
                                    value: 5,
                                    defaultCheck: planRole === 5,
                                },
                                {
                                    label: "Students",
                                    value: 3,
                                    defaultCheck: planRole === 3,
                                },
                            ]}
                            handleChange={(e) => {
                                setFieldValue("role", e.target.value);
                            }}
                            name="role"
                            label="Select a user :"
                            error={errors?.role}
                            disabled={!!planRole}
                        />
                        <GridEqual
                            className="grid-input"
                            count={2}
                            gap={"20px"}
                        >
                            <Input
                                handleChange={handleChange}
                                name="first_name"
                                id="first_name"
                                placeholder=""
                                label="First Name"
                                error={errors?.first_name}
                                errors={regErrors?.filter(
                                    ({ path }) => path === "first_name"
                                )}
                            />

                            <Input
                                handleChange={handleChange}
                                name="last_name"
                                id="last_name"
                                placeholder=""
                                label="Last Name"
                                error={errors?.last_name}
                                errors={regErrors?.filter(
                                    ({ path }) => path === "last_name"
                                )}
                            />
                        </GridEqual>

                        <Input
                            handleChange={handleChange}
                            name="email"
                            id="email"
                            placeholder=""
                            label="Email"
                            type="email"
                            error={errors?.email}
                            errors={regErrors?.filter(
                                ({ path }) => path === "email"
                            )}
                        />

                        <Input
                            handleChange={handleChange}
                            name="phone_number"
                            id="phone_number"
                            placeholder=""
                            label="Mobile number"
                            type="phone_number"
                            error={errors?.phone_number}
                            errors={regErrors?.filter(
                                ({ path }) => path === "phone_number"
                            )}
                        />

                        <Input
                            handleChange={handleChange}
                            name="contactAddress"
                            id="contactAddress"
                            placeholder=""
                            label="Contact Address"
                            type="contactAddress"
                            error={errors?.contactAddress}
                            errors={regErrors?.filter(
                                ({ path }) => path === "contactAddress"
                            )}
                        />

                        <Input
                            handleChange={handleChange}
                            name="refferal_code"
                            id="refferal_code"
                            placeholder=""
                            defaultValue="SPIDEL"
                            label="Referral Code"
                            error={errors?.refferal_code}
                            errors={regErrors?.filter(
                                ({ path }) => path === "refferal_code"
                            )}
                        />

                        <Input
                            handleChange={handleChange}
                            name="password"
                            id="password"
                            placeholder=""
                            label="Password"
                            type="password"
                            error={errors?.password}
                            errors={regErrors?.filter(
                                ({ path }) => path === "password"
                            )}
                        />

                        <Input
                            handleChange={handleChange}
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder=""
                            label="Confirm Password"
                            type="password"
                            error={errors?.confirmPassword}
                        />
                        <button type="submit mb-8" disabled={loading}>
                            {loading ? <ButtonLoader /> : "Register"}
                        </button>
                        <Link
                            className="auth-signup mt-8"
                            to={
                                plan && !plan.length
                                    ? "/login"
                                    : `/login?plan_code=${plan}&role=${planRole}&spc=${spc}` // use redirectUrl =>
                            }
                        >
                            Already have account? Login
                        </Link>
                    </form>
                </Content>
                <ImgStyle src={law} />
            </Container>
        </Wrapper>
    );
};

export default Register;
