import React, { useState, useEffect } from "react";
import check from "assets/check.svg";
import Plans404 from "components/Plans404";
import history from "utils/history";
import { ButtonLoader } from "components/Loaders";

function PlanFeatures({ features }) {
  return (
    <React.Fragment>
      <div className="mt-8">
        {features.map((feature, i) => (
          <div key={i} className="flex items-center my-2">
            <img alt={"check"} src={check} />
            <p className="ml-2 text-md font-semibold text-gray-800">
              {feature}
            </p>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

// Case: Not Authenticated
function HandlePurchase(plan, role, spc) {
  history.push(`/register?plan_code=${plan}&role=${role}&spc=${spc}`);
  window.location.reload();
}

export default function SubscriptionPlans({
  plans,
  purchaseSubscription,
  isAuthenticated,
  psLoading,
  role,
  spc,
  plan_from_link,
}) {
  const [processing, setProcessing] = useState(null);
  const [extLoading, setExtLoading] = useState(false);

  useEffect(() => setExtLoading(psLoading), [psLoading]);

  return (
    <div className="mx-8 mt-10 price-box">
      <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 grid gap-4">
        {plans.length ? (
          plans.map((plan) => (
            <div
              key={plan.subscription_plan_id}
              className="px-5 py-6 full-width rounded-lg border border-gray-200"
            >
              <div>
                <p className="mb-3 text-lg custom-text-green font-semibold">
                  {plan.name}
                </p>
                <div className="flex items-center">
                  <p className="text-3xl font-bold custom-text-green">
                    ₦{plan.amount}
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  if (isAuthenticated) {
                    setProcessing(plan.plan_code);
                    purchaseSubscription({
                      variables: {
                        info: {
                          plan: plan.plan_code,
                          region: "ng",
                          redirect_url: `${window.location.origin}/completed_subscription_purchase/${plan.subscription_plan_id}/${plan.plan_code}`,
                        },
                        provider: plan.provider,
                      },
                    });
                  } else {
                    setProcessing(plan.plan_code);
                    setExtLoading(true);
                    setTimeout(
                      () => HandlePurchase(plan.plan_code, role, spc),
                      1500
                    );
                  }
                }}
                className="w-full mt-8 custom-border-op py-3 rounded-lg text-md font-bold"
              >
                {(extLoading && processing === plan.plan_code) ||
                plan_from_link === plan.plan_code ? (
                  <ButtonLoader />
                ) : (
                  "buy plan"
                )}
              </button>
              <PlanFeatures features={plan.feature_list} />
            </div>
          ))
        ) : (
          <Plans404 />
        )}
      </div>
    </div>
  );
}

/**
 * original-price:
 *  <p className="text-sm font-medium text-gray-500 mt-4 mb-2">
 * Original price <span className="line-through">N3,000.00</span>
 * </p>
 *
 * usd:
 * <div className="flex items-center mt-1">
 * <p className="text-md font-bold custom-text-green">$8</p>
 * <p className="text-sm font-medium text-gray-400 ml-2">/ Month</p>
 *
 * /{interval}
 * <p className="text-sm font-medium text-gray-400 ml-2">
 * / {plan.interval}
 * </p>
 *
 * <p className="text-sm text-gray-500 font-medium my-4">
 * includes mobile
 * </p>
 */
