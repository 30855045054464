import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Down() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Downloads</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I want to use WRN offline?" triggerTagName="div">
                <p className="text-md my-4">There are three (3) versions of WRN</p>
                <p className="text-md my-4">In order to use WRN Offline, you have to first make a subscription for WRN Offline</p>
                <p className="text-md my-4">View <a href="/features" className="underline font-medium">WRN Offline subscription</a> page</p>
                <p className="text-md my-4">Download WRN <a href="/features" className="underline font-medium"> Offline </a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Where can I download WRN Offline?" triggerTagName="div">
                <p className="text-md my-4">In order to download WRN Offline, please use the download link on the website.</p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Download WRN Offline</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I download the cases from the website?" triggerTagName="div">
                <p className="text-md my-4">There is currently no provision for users to download all the cases from the website.</p>
                <p className="text-md my-4">Users can however perform other functions such as print and copy.</p>
                <p className="text-md my-4">TO have access to WRN offline, please subscribe for WRN OFFLINE</p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Subscribe for WRN Offline</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I download books from the WRN store?" triggerTagName="div">
                <p className="text-md my-4">Once you buy a book from the WRN Store, you are able to download it, or add it to your WRN bookshelf via your WRN dashboard</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I download books from the WRN store?" triggerTagName="div">
                <p className="text-md my-4">Please, visit the downloads page from your accounts to see the downloads and books that have been purchased.</p>
                <p className="text-md my-4">If you intend to subscribe for WRN Offline, please <a href="/features" className="underline font-medium">click here</a></p>
                <p className="text-md my-4">If you intend to visit the WRN store to buy books, <a href="/store" className="underline font-medium">click here</a></p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Down;
