import React, { useEffect } from "react";
import Swal from "sweetalert2";

import { PageLoader } from "components/Loaders";
import Home from "pages/Home";
import { useLazyQuery } from "@apollo/client";
import { GET_SUB } from "schema/subscription-plans";

/**
 * Decide: (from successful payments)
 * - GoTo: UserDashboard
 * - stay on Home :)
 */
export default function CompletedSubscriptionPurchase({ history, match }) {
  const [getSub, { data }] = useLazyQuery(GET_SUB);

  useEffect(() => getSub({ variables: { id: parseInt(match.params.id) } }), []);

  // params :=> {userId, plan}
  useEffect(() => {
    if (data)
      Swal.fire({
        title: "Welcome Back 👋",
        text: "Would you like to continue on the dashboard? 👀",
        icon: "question",
        footer: `<p style="color:green;">"${data.subscription_plan?.name}" plan purchase was successful</p>`,
        confirmButtonText: "Yes",
        confirmButtonColor: "green",
        showDenyButton: true,
        denyButtonText: "No",
        denyButtonColor: "#FAE441",
        allowOutsideClick: false,
      }).then((result) => {
        const token = localStorage.getItem("wrn_user_dashboard_token") || "";
        if (result.isConfirmed) {
          // goToDashBoard -
          window.open(
            `${process.env.REACT_APP_DBOARD_URL_}/login/${token}?redirectUrl=dashboard.subscriptions`,
            "_self"
          );
        } else if (result.isDenied) {
          // goToHOme
          history.push("/");
        }
      });
  }, [data]);

  return (
    <React.Fragment>
      <Home />
      <PageLoader />
    </React.Fragment>
  );
}
