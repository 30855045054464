import React, { useContext, useEffect } from "react";
import arr from "assets/arrow-right.svg";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field, Form } from "formik";
import notify from "utils/toast";
import { ContactsContext } from "context/contacts";
import { PageLoader } from "components/Loaders";

export const ContactUsSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  enq: yup.string().required("Required"),
  detail: yup.string().required("Required"),
});

export default function ContactUs() {
  const { ncr, loading, data } = useContext(ContactsContext);

  useEffect(() => {
    if (data && data.new_contact_request && data.new_contact_request.ok) {
      notify("Message Sent 😁", "success");
      setTimeout(() => window.location.reload(), 3000);
    }
  }, [data]);

  return (
    <div className="content">
      {loading && <PageLoader />}
      <div className="flex items-center my-8 mx-8">
        <Link to="/contact" className="text-gray-500 font-medium text-sm">
          Contact Us >
        </Link>
        <p className="text-gray-800 font-medium text-sm mx-2">
          General Contact
        </p>
      </div>
      <p className="font-bold text-3xl my-2 mx-8 custom-text-green">
        Contact WRN
      </p>
      <p className="font-semibold mx-8">
        Our dedicated team is always available to attend to all your enquiries.
      </p>
      <div className="my-10">
        <div className="flex blo-ck">
          <div className="ml-reset custom-form rounded-md">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                enq: "",
                org: "",
                detail: "",
              }}
              validationSchema={ContactUsSchema}
              onSubmit={(values) => {
                ncr({
                  variables: {
                    category: 1,
                    email: values.email,
                    enquiry: values.detail,
                    enquiry_type: values.enq,
                    name: values.firstName + values.lastName,
                    organization: values.org,
                    phone: values.phone,
                  },
                });
              }}
            >
              {({ errors, touched }) => (
                <Form className="px-10 form-box">
                  <div className="my-4">
                    <label
                      className="text-sm font-semibold"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      className={`${
                        errors.firstName && touched.firstName
                          ? "border border-red-600"
                          : ""
                      } px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black`}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="text-red-600 text-sm mt-2">
                        {errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="lastName">
                      Last Name
                    </label>
                    <Field
                      className={`${
                        errors.lastName && touched.lastName
                          ? "border border-red-600"
                          : ""
                      } px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black`}
                      name="lastName"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="text-red-600 text-sm mt-2">
                        {errors.lastName}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="email">
                      Email Address
                    </label>
                    <Field
                      type="email"
                      className={`${
                        errors.email && touched.email
                          ? "border border-red-600"
                          : ""
                      } px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black`}
                      name="email"
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-600 text-sm mt-2">
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="phone">
                      Phone Number
                    </label>
                    <Field
                      className="px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black"
                      name="phone"
                    />
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="enq">
                      What kind of support do you need?
                    </label>
                    <Field
                      as="select"
                      className={`${
                        errors.enq && touched.enq ? "border border-red-600" : ""
                      } px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black outline-none appearance-none`}
                      name="enq"
                    >
                      <option selected hidden>
                        Select Enquiry Category
                      </option>
                      <option>WRN Plans & Discounts</option>
                      <option>Contribute to WRN</option>
                      <option>WRN Volumes & Books</option>
                      <option>Complaints & Feedbacks</option>
                      <option>Other Enquiries</option>
                    </Field>
                    {errors.enq && touched.enq ? (
                      <div className="text-red-600 text-sm mt-2">
                        {errors.enq}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="org">
                      Chambers/Company/School Name
                    </label>
                    <Field
                      className="px-4 py-2 my-2 w-full border border-gray-200 rounded-md text-black"
                      name="org"
                    />
                  </div>
                  <div className="my-4">
                    <label className="text-sm font-semibold" htmlFor="detail">
                      Detail your enquiry
                    </label>
                    <Field
                      as="textarea"
                      className={`${
                        errors.detail && touched.detail
                          ? "border border-red-600"
                          : ""
                      } px-4 py-2 my-2 w-full border outline-none border-gray-200 rounded-md text-black`}
                      name="detail"
                    ></Field>
                    {errors.detail && touched.detail ? (
                      <div className="text-red-600 text-sm mt-2">
                        {errors.detail}
                      </div>
                    ) : null}
                  </div>
                  <button
                    className="custom-bg-green text-white mb-4 font-bold rounded-lg py-3 w-full"
                    type="submit"
                  >
                    Send
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="w-1/3 ml-reset full-width top p-4 mx-2 p-2">
            <div>
              <p className="text-black text-2xl mb-3 font-semibold">
                Sales Enquiry?
              </p>
              <p className="custom-text-green text-md">
                For Subscription and Sales Enquiries
              </p>
              <div className="my-8">
                <Link
                  to="/contact/sales"
                  className="custom-text-green font-bold flex items-center"
                >
                  Contact Sales <img src={arr} className="ml-1" />
                </Link>
                <Link
                  to="/support"
                  className="custom-text-green font-bold flex my-2 items-center"
                >
                  See our FAQs <img src={arr} className="ml-1" />
                </Link>
              </div>
            </div>
            <hr className="border border-gray-100 w-full my-6" />
            <div>
              <p className="text-black text-2xl mb-3 font-semibold">
                Sales Enquiry?
              </p>
              <p className="custom-text-green text-md">
                For technical, account or billing issues
              </p>
              <div className="my-8">
                <Link
                  to="/support"
                  className="custom-text-green font-bold flex my-2 items-center"
                >
                  See our FAQs <img src={arr} className="ml-1" />
                </Link>
                <Link
                  to="/contact/tech"
                  className="custom-text-green font-bold flex items-center"
                >
                  Contact Support <img src={arr} className="ml-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
