import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function AccSettings() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Account Settings</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I want to reset my password" triggerTagName="div">
                <p className="text-md my-4">From the dashboard, look for the round icon on the top right. Click on that icon, and select Account. You would see the option to reset your account by setting a new password.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I want to check my profile" triggerTagName="div">
                <p className="text-md my-4">You can check your profile by visiting the top right hand corner of the dashboard, after logging into WRN.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I think my account has been compromised" triggerTagName="div">
                <p className="text-md my-4">If you believe your account has been compromised, please reset your password using the reset password link on the login page. You can also reset your password from your account settings.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/forgot-password" className="underline font-medium">Reset password</a></p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccSettings;
