import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { isTokenValid } from "utils/auth";
import { AuthContext } from "context/auth";

export default function WebRoute({
  layout: Layout,
  component: Component,
  auth,
  title,
  ...rest
}) {
  const { setIsAuthenticated } = React.useContext(AuthContext);
  useEffect(() => {
    if (!localStorage.wrn_user || !isTokenValid()) {
      localStorage.removeItem("wrn_user");
      localStorage.removeItem("wrn_user_dashboard_token");
      setIsAuthenticated(false);
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout title={title}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
