import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function RegLog() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Registration & Login</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How do I register for WRN" triggerTagName="div">
                <p className="text-md my-4">Click the login link on the homepage, and you would be redirected to the registration link if you have not registered.</p>
                <p className="text-md my-4">You can also get a trial period to access WRN and its features.</p>
                <p className="text-md my-4"><a className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I have registered, but I can’t sign in." triggerTagName="div">
                <p className="text-md my-4">If you have registered to use WRN and you cannot sign in, please confirm that you got an email detailing your registration status. If you got this email, please, proceed to reset your account using the registration details that you previously supplied.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/register" className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Ways to Register / sign in" triggerTagName="div">
                <p className="text-md my-4">You can register to use WRN via the following methods:</p>
                <p className="text-md my-4"><span className=" mr-2">1.</span>Registering with your email address</p>
                <p className="text-md my-4"><span className=" mr-2">2.</span>Register using your Google account by clicking on the Google link.</p>
                <p className="text-md my-4"><span className=" mr-2">3.</span>Social media login via your preferred social media handle. Such as facebook and twitter.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/register" className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I want to register to use WRN for free" triggerTagName="div">
                <p className="text-md my-4">You can register to use WRN for free if you have not previously registered. New users who wish to use WRN for free, are automatically given a one month free period to access WRN.</p>
                <p className="text-md my-4">To get this, please, click on the FREE TRIAL link on the homepage.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/register" className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What are the requirements to register?" triggerTagName="div">
                <p className="text-md my-4">Depending on your User category, you need the following:</p>
                <p className="text-md my-4"><span className=" mr-2">1.</span>Lawyer: email address</p>
                <p className="text-md my-4"><span className=" mr-2">2.</span>Student: Matric Number & Email address</p>
                <p className="text-md my-4"><span className=" mr-2">3.</span>Law School Student: Registration Number & Email address</p>
                <p className="text-md my-4"><span className=" mr-2">4.</span>Enterprise: Email addresses</p>
                <p className="text-md my-4"><span className=" mr-2">5.</span>Others: email address</p>
                <p className="text-md my-4"><a className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Do I have to register on my laptop and phone as well?" triggerTagName="div">
                <p className="text-md my-4">You are only required to register once for WRN. Once you have registered, you would be able to access WRN on your laptop, as well as on your phone.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/register" className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I cannot remember my login details" triggerTagName="div">
                <p className="text-md my-4">If you cannot remember your login details, please reset your account. You would be asked to provide your email address, after which a link would be sent to you to reset your account.</p>
                <p className="text-md my-4">To reset your account, please, click the “forgot password” link in the login page.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/forgot-password" className="underline font-medium">Forgot password</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I want to change my registration email" triggerTagName="div">
                <p className="text-md my-4">If you want to change your registration email, you have to be logged into WRN.</p>
                <p className="text-md my-4">Go to your Account Settings and then Profile, you would see the option to change your email address. You would then have to provide the new email address, and update your account.</p>
                <p className="text-md my-4">After this, you would get notifications on both your old and new email address about the change in email address.</p>
                <p className="text-md my-4">When next you are signing in, you are required to use the new email address</p>
                <p className="text-md my-4">Do you think your account has been compromised? <a href="https://weeklyreportsofnigeria.com/forgot-password" className="underline font-medium">Reset Account</a></p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegLog;
