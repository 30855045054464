import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import icons from "assets";
import { HomeContext } from "context/home";
import {
  judgement_list_pagination,
  web_display_blogs_pagination,
} from "utils/constants";
import { useQuery } from "@apollo/client";
import { web_display } from "schema/articles";
import { AuthContext } from "context/auth";
import { openSummaryIfNoAuthentication_GoToDashboardIfAuth } from "utils/summary";

function JudgementLists({ history }) {
  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  const { judgementListMostRecent, getJL } = useContext(HomeContext); // [load.icon]: jlLoadingg
  const { isAuthenticated } = useContext(AuthContext);

  const [court_category, setCourtCategory] = useState("sc");

  useEffect(() => {
    getJL({
      variables: {
        court_category: 2,
        region: "ng",
        recent: {
          pagination: judgement_list_pagination,
          last_date: Math.round(new Date(Date.now()).getTime() / 1000),
        },
      },
    });
  }, []);

  return (
    <div className="rounded-lg">
      <div className="custom-left-width">
        <p className="custom-text-green p-2 text-xl my-6 font-bold">
          Recent Judgment
        </p>
      </div>
      <div className="border">
        <div className="flex border items-center">
          <button
            onClick={() => setCourtCategory("sc")}
            className={classnames("py-5 px-8 text-gray-800 font-bold", {
              "bg-white custom-border-bot": court_category === "sc",
              "bg-gray-100 border": court_category !== "sc",
            })}
          >
            Supreme Court
          </button>
          <button
            onClick={() => setCourtCategory("ca")}
            className={classnames("py-5 px-8 text-gray-800 font-bold", {
              "bg-white custom-border-bot": court_category === "ca",
              "bg-gray-100 border": court_category !== "ca",
            })}
          >
            Court of Appeal
          </button>
        </div>

        {/*render supreme courts|court of appeal*/}
        <div className="">
          {judgementListMostRecent[court_category]?.map(
            ({ title, formatted_date, case_no, case_id: id, court_id }) => (
              <div
                key={id}
                onClick={() => {
                  openSummaryIfNoAuthentication_GoToDashboardIfAuth(
                    history,
                    isAuthenticated,
                    token,
                    court_id,
                    id
                  );
                }}
                className="p-4 border"
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="flex items-center">
                  <p className="font-bold text-black">{title}</p>
                  <p className="font-bold custom-text-yellow ml-auto none no-ne n-one">
                    {formatted_date}
                  </p>
                </div>
                <p className="custom-text-yellow my-2">{case_no}</p>
                <p className="font-bold custom-text-yellow ml-auto date-res">
                  {formatted_date}
                </p>
              </div>
            )
          )}
        </div>

        <div className="flex p-4">
          <button
            onClick={() => {
              history.push(`/judgement/${court_category}`);
            }}
            className="custom-text-green ml-auto underline font-bold"
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
}

function Articles() {
  const { data } = useQuery(web_display, {
    variables: {
      options: {
        skip: 0,
        take: web_display_blogs_pagination,
        order: { key: "created_at", value: -1 },
      },
    },
  });

  return (
    <div className="">
      <p className="custom-text-green p-2 text-xl my-6 font-bold">
        WRN News and Articles
      </p>
      <div className="border">
        <div className="mb-6">
          <img alt={"image"} src={icons.nanda} className="w-full nanda" />
        </div>
        <div>
          {data?.blogs?.data.map(
            ({ id, created_at, title, author: { first_name, last_name } }) => {
              return (
                <div className="border-b p-4" key={id}>
                  <p className="text-black max-w-lg font-bold">{title}</p>
                  <p className="text-gray-500 my-2">
                    {`${first_name}${last_name}`} |{" "}
                    {moment(new Date(parseInt(created_at))).format(
                      "MMM Do YYYY"
                    )}
                  </p>
                </div>
              );
            }
          )}

          <div className="flex my-4 p-2">
            <button
              onClick={() => console.log("[goto.blog]")}
              className="custom-text-green ml-auto underline font-bold"
            >
              View All Posts
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function MiddleSection({ history }) {
  return (
    <div className="mx-20 m-x-8 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4 blo-ck my-16">
      <JudgementLists history={history} />
      <Articles />
    </div>
  );
}
