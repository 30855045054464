import React, { useContext } from "react";
import logo from "assets/logo.svg";
// import cart from "assets/cart.svg";
import { ArrowRightCircle, LogOut, Settings } from "react-feather";
import UserAvatar from "react-user-avatar";
import nav_profile from "assets/navprofile.svg";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "components/Sidebar";
import "assets/css/navbar.css";
import { AuthContext } from "context/auth";

export default function Navbar() {
  const { isAuthenticated, me, logout, meQuery } = useContext(AuthContext);
  React.useEffect(() => meQuery(), []);
  return (
    <div className="py-8 px-8 bg-white shadow p-phone">
      <div
        style={{ marginLeft: "-10px" }}
        className="content flex items-center none no-ne"
      >
        {/*Logo*/}
        <NavLink className="logo-header" to="/">
          <img alt={"logo"} className="img-width w-64" src={logo} />
        </NavLink>
        <div className="ml-auto flex items-center nav-menu-links">
          <StaticRender />
          <AuthenticatedRender
            me={me}
            isAuthenticated={isAuthenticated}
            logout={logout}
          />
        </div>
      </div>
      <MobileMenu />
    </div>
  );
}

function AuthenticatedRender({ me, isAuthenticated, logout }) {
  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  return (
    <React.Fragment>
      {!isAuthenticated && (
        <React.Fragment>
          {/*<NavLink*/}
          {/*  to=""*/}
          {/*  onClick={(e) => {*/}
          {/*    console.log("[free.trial]");*/}
          {/*    e.preventDefault();*/}
          {/*  }}*/}
          {/*  className="py-1 px-4 custom-bot-nav font-semibold text-green-700 rounded-lg text-lg mx-2"*/}
          {/*>*/}
          {/*  Free Trial*/}
          {/*</NavLink>*/}
          <NavLink to="/login" className="text-lg mx-2 text-green-700">
            Sign in
          </NavLink>
          <NavLink
            to="/register"
            className="py-1 px-4 custom-bot-nav font-semibold text-green-700 rounded-lg text-lg mx-2"
          >
            Sign up
          </NavLink>
        </React.Fragment>
      )}

      {isAuthenticated && (
        <div className="ml-18 mx-4 flex items-center">
          <UserAvatar
            size="40"
            style={{
              color: "white",
              fontWeight: "400",
              userSelect: "none",
              marginRight: "5px",
              border: "8px solid #ffebf4",
              borderRadius: "50%",
            }}
            name={`${me?.first_name} ${me?.last_name}`}
            src={me?.profile_pic ? me?.profile_pic : nav_profile}
          />
          <div className="relative drop--down">
            <button className="dropdown--head-button">
              <svg
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.814819 0.591797L5.44445 5.22143L10.0741 0.591797H0.814819Z"
                  fill="#27750A"
                />
              </svg>
            </button>
            <div className="dropdown--content rounded-lg z-10">
              <div className="bg-white p-4">
                <p style={{ fontSize: 16, fontWeight: "400" }}>Signed in as</p>
                <p
                  style={{ fontSize: 16, fontWeight: "700" }}
                >{`${me?.first_name} ${me?.last_name}`}</p>
              </div>
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_DBOARD_URL_}/login/${token}`,
                    "_self"
                  );
                }}
              >
                <div className="flex items-center">
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <ArrowRightCircle size={20} />
                  </div>
                  <p className="text-base">Go to dashboard</p>
                </div>
              </Link>
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_DBOARD_URL_}/login/${token}?redirectUrl=dashboard.settings`,
                    "_self"
                  );
                }}
              >
                <div className="flex items-center">
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <Settings size={20} />
                  </div>
                  <p className="text-base">Settings</p>
                </div>
              </Link>
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  logout({ variables: { type: "" } });
                }}
              >
                <div className="flex items-center">
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <LogOut size={20} />
                  </div>
                  <p className="text-base">Logout</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function StaticRender() {
  return (
    <React.Fragment>
      <NavLink
        to="/about"
        activeClassName="custom-active"
        className="text-lg mx-2"
      >
        About Us
      </NavLink>
      <NavLink
        to="/features"
        activeClassName="custom-active"
        className="text-lg mx-2"
      >
        Features
      </NavLink>
      <NavLink
        to="/support"
        activeClassName="custom-active"
        className="text-lg mx-2"
      >
        Support
      </NavLink>
      <NavLink
        to="/contact"
        activeClassName="custom-active"
        className="text-lg mx-2"
      >
        Contact
      </NavLink>
    </React.Fragment>
  );
}

function MobileMenu() {
  return (
    <div className="show-mob-menu hide-for-phone flex items-center">
      <NavLink className="" to="/">
        <img alt={"logo"} className="img-width w-80" src={logo} />
      </NavLink>
      <Sidebar />
    </div>
  );
}
/*<NavLink*/
/*  to="/store"*/
/*  activeClassName="custom-active"*/
/*  className="text-lg mx-2"*/
/*>*/
/*  Store*/
/*</NavLink>*/
/*<NavLink to="/cart" className="mr-4 ml-16">*/
/*  <img src={cart} />*/
/*</NavLink>*/
