import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

export default function SubscriptionPlanClass({ spc, setSpc }) {
  return (
    <React.Fragment>
      <div className="flex about-link mx-auto">
        <Link
          to=""
          onClick={(e) => {
            e.preventDefault();
            setSpc(1);
          }}
          className={classnames("py-4 tab-fea text-black font-medium", {
            "custom-border-bot": spc === 1,
          })}
        >
          WRN ONLINE
        </Link>
        <Link
          to=""
          onClick={(e) => {
            e.preventDefault();
            setSpc(3);
          }}
          className={classnames("py-4 tab-fea text-black font-medium", {
            "custom-border-bot": spc === 3,
          })}
        >
          WRN OFFLINE
          <span className="py-1 top-3 ml-top text-sm ml-6 px-3 custom-bg-yellow text-white">
            New
          </span>
        </Link>
        <Link
          to=""
          onClick={(e) => {
            e.preventDefault();
            setSpc(2);
          }}
          className={classnames("py-4 tab-fea text-black font-medium", {
            "custom-border-bot": spc === 2,
          })}
        >
          ENTERPRISE
          <span className="py-1 top-3 ml-top text-sm ml-4 px-3 custom-bg-yellow text-white">
            New
          </span>
        </Link>
      </div>
    </React.Fragment>
  );
}
