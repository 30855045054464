import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import "assets/css/search.css";
import Pagination from "components/Pagination";
import EmptyState from "components/EmptyState";
import CasesTab from "./CasesTab";
import { BlockLoader } from "components/Loaders";
import { yearsFuncReverse } from "utils/yearsFunc";
import { SEARCH_EXTRA_CC, SEARCH_EXTRA_JUSTICES } from "schema/searches";
import { SearchContext } from "context/search";
import icons from "assets";
import DictionaryTab from "./DictionaryTab";
import bi from 'assets/img/bi_filter.svg'
import useVisiblityToggler from "components/hooks/useVisiblityToggler"

function CourtCategoryFilters({ region, setVariables }) {
  const { data } = useQuery(SEARCH_EXTRA_CC, {
    variables: { region },
  });

  return (
    <div className="search-filter">
      <div className="card-heading">
        <p>Filter by court</p>
      </div>
      <div className="card-body">
        {data?.se_court_categories.map((cc) => (
          <div className="filter" key={cc.court_id}>
            <input
              type="checkbox"
              name="Court of Appeal"
              id={cc.court_id}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  // setVariables
                  setVariables(({ filters, ...rest }) => {
                    return {
                      ...rest,
                      filters: {
                        ...filters,
                        court_id: [...filters.court_id, cc.court_id],
                      },
                    };
                  });
                } else {
                  // setVariables
                  setVariables(
                    ({
                      filters: { court_id: court_ids, ...remainingFilters },
                      ...rest
                    }) => {
                      return {
                        ...rest,
                        filters: {
                          ...remainingFilters,
                          court_id: court_ids.filter((x) => x !== cc.court_id),
                        },
                      };
                    }
                  );
                }
              }}
            />
            <p>{cc.court_category}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function JusticesFilter({ region, setVariables }) {
  const { data } = useQuery(SEARCH_EXTRA_JUSTICES, {
    variables: { region },
  });

  return (
    <div className="search-filter">
      <div className="card-heading">
        <p>Filter by justice</p>
      </div>
      <div className="card-body">
        {data?.se_justices.map((justice) => (
          <div className="filter" key={justice.justice_id}>
            <input
              type="checkbox"
              name="Justice"
              id={justice.justice_id}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  // setVariables
                  setVariables(({ filters, ...rest }) => {
                    return {
                      ...rest,
                      filters: {
                        ...filters,
                        justice: [...filters.justice, justice.justice_id],
                      },
                    };
                  });
                } else {
                  // setVariables
                  setVariables(
                    ({
                      filters: { justice: justices, ...remainingFilters },
                      ...rest
                    }) => {
                      return {
                        ...rest,
                        filters: {
                          ...remainingFilters,
                          justice: justices.filter(
                            (x) => x !== justice.justice_id
                          ),
                        },
                      };
                    }
                  );
                }
              }}
            />
            <p>{justice.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function YearFilter({ setVariables }) {
  const currentYear = new Date().getFullYear();
  const years = yearsFuncReverse(currentYear - (currentYear - 2000)); // 2000 is start year

  return (
    <div className="search-filter">
      <div className="card-heading">
        <p>Filter by year</p>
      </div>
      <div className="card-body">
        {years.map((year) => (
          <div className="filter" key={year}>
            <input
              type="checkbox"
              name="Year"
              id={year}
              onChange={(event) => {
                const checked = event.target.checked;
                if (checked) {
                  // setVariables
                  setVariables(({ filters, ...rest }) => {
                    return {
                      ...rest,
                      filters: {
                        ...filters,
                        year: [...filters.year, year.toString()],
                      },
                    };
                  });
                } else {
                  // setVariables
                  setVariables(
                    ({
                      filters: { year: years, ...remainingFilters },
                      ...rest
                    }) => {
                      return {
                        ...rest,
                        filters: {
                          ...remainingFilters,
                          year: years.filter((x) => x !== year.toString()),
                        },
                      };
                    }
                  );
                }
              }}
            />
            <p>{year.toString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function RecentSearches() {
  return (
    <div id="recent-search-container" className="desktop-only">
      <p className="bold">Recent Search</p>
      <div id="recent-searches">
        <div className="recent-search">
          <p>supreme court on forgery</p>
          <img src="../assets/img/close.svg" alt="" />
        </div>
        <div className="recent-search">
          <p>supreme court on forgery</p>
          <img src="../assets/img/close.svg" alt="" />
        </div>
        <div className="recent-search">
          <p>supreme court on forgery</p>
          <img src="../assets/img/close.svg" alt="" />
        </div>
      </div>
      <p id="btn-tetiary">view all</p>
    </div>
  );
}

function SearchResult({
  loading,
  data,
  query,
  setVariables,
  take,
  history,
  count,
  region,
  tab,
}) {

  const [FilterComponent, toggleCardVisiblity] = useVisiblityToggler(
    <div>
      <CourtCategoryFilters region={region} setVariables={setVariables} />
      <YearFilter setVariables={setVariables} />
      <JusticesFilter region={region} setVariables={setVariables} />
    </div>,
    false
  );

  return (
    <div className="inner-container mb-16 m-x-8 mx-20">
      <React.Fragment>
        <div id="content">
          <div id="main-content">
            <div className="search-results">
              <div className="filters-mobile">
                <div id="search-filters">
                  <div style={{ marginTop: 20, marginBottom: 20, display: 'flex' }}>
                    <button
                      onClick={toggleCardVisiblity}
                      style={{ 
                        appearance: 'none', 
                        backgroundColor: '#f9f9f9', 
                        paddingRight: 20, 
                        paddingLeft: 20, 
                        paddingTop: 10, 
                        paddingBottom: 10, 
                        border: 'none', 
                        marginLeft: 'auto', 
                        display: 'flex', 
                        alignItems: 'center',
                        borderRadius: 8 
                      }}
                    >
                      <img src={bi} />
                      <p style={{ marginLeft: 5 }}>Filter</p>
                    </button>
                  </div>
                  {FilterComponent}
                </div>
              </div>
              <div className="card-heading">
                {!loading && (
                  <p>
                    <span className="bold">{count} result(s)</span> found
                    meeting search criteria. keyword: {query}
                  </p>
                )}
              </div>

              {loading && <BlockLoader />}

              {tab === "cases" && (
                <CasesTab
                  data={data?.search?.cases}
                  history={history}
                  region={region}
                />
              )}

              {tab === "dictionary" && (
                <DictionaryTab data={data?.search?.words} />
              )}

              {count === 0 && <EmptyState text="No search result" />}

              {count ? (
                <Pagination
                  getFunction={(skip, take_) => {
                    window.scrollTo(0, 0); // Scroll to Top ^_^
                    setVariables((previous) => {
                      return {
                        ...previous,
                        options: {
                          skip,
                          take: take_,
                        },
                      };
                    });
                  }}
                  count={count}
                  pageSize={take}
                />
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
          {tab === "cases" && (
            <div id="side-content" className="desktop-only">
              <div id="search-filters">
                <CourtCategoryFilters
                  region={region}
                  setVariables={setVariables}
                />
                <YearFilter setVariables={setVariables} />
                <JusticesFilter region={region} setVariables={setVariables} />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  );
}

function runSearch(history, tab, search) {
  history.push(`/search?q=${search}&tab=${tab}`);
}

export default function SearchWrapper({ history }) {
  const take = 10;
  const region = "ng";
  const tab_from_url =
    new URLSearchParams(history.location.search).get("tab") || "cases";
  const query = new URLSearchParams(history.location.search).get("q");

  const [variables, setVariables] = React.useState({
    query,
    filters: { court_id: [], year: [], justice: [] },
    tab: tab_from_url,
    options: {
      skip: 0,
      take,
    },
  });

  const user = localStorage?.wrn_user ? JSON.parse(localStorage?.wrn_user) : {};
  const [count, setCount] = React.useState(0);
  const { search, data, loading, setTab, tab } = useContext(SearchContext);

  const [searchQuery, setSearchQuery] = React.useState("");

  React.useEffect(() => {
    // update variables on tab change
    if (tab) {
      setVariables((previous) => {
        return {
          ...previous,
          tab,
        };
      });
    }
  }, [tab]);

  React.useEffect(() => {
    // update tab for currentTab persistence on new query
    if (tab_from_url) setTab(tab_from_url);
  }, [tab_from_url]);

  React.useEffect(() => {
    if (query) {
      setSearchQuery(query);
      setVariables((previous) => {
        return {
          ...previous,
          query,
        };
      });
    }
  }, [query]);

  React.useEffect(() => {
    if (variables) {
      search({ variables });
    }
  }, [variables]);

  React.useEffect(() => {
    if (data?.search?.total_count) {
      if (data.search.total_count !== count) {
        setCount(data.search.total_count);
      }
    } else setCount(0);
  }, [data]);

  /**
   * TODO: Add tab switch function
   * TODO: Add dictionary page
   * TODO: Add Recent Searches | WEB-DASHBOARD-DATA.ENTRY
   */

  return (
    <React.Fragment>
      <div className="my-6 mx-20 m-x-8">
        <form
          className="block sm:block md:block lg:flex xl:flex 2xl:flex items-center"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex w-full">
            <input
              type="text"
              className="w-full rounded-lg py-4 px-4 border-2 border-yellow-600"
              placeholder="Search for judgments, indexes and other volumes..."
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter" && search) {
                  runSearch(history, tab, searchQuery);
                }
              }}
              value={searchQuery}
            />
            <button
              onClick={() => {
                if (search) runSearch(history, tab, searchQuery);
              }}
              className="py-4 px-6 custom-bg-green rounded-r-lg rounded-l-none -ml-2"
            >
              <img alt={"image"} src={icons.search} />
            </button>
          </div>
          <div id="search-suggestion-box">
            <div
              onClick={() => {
                setTab("cases");
                history.push(`/dashboard/result?q=${search}&tab=cases`);
              }}
              className="search-suggestion"
            >
              <p>Case</p>
            </div>

            <div
              onClick={() => {
                if (search) {
                  setTab("dictionary");
                  history.push(`/dashboard/dictionary`);
                }
              }}
              className="search-suggestion"
            >
              <p>Dictionary</p>
            </div>
          </div>
        </form>
      </div>
      <SearchResult
        data={data}
        loading={loading}
        query={query}
        variables={variables}
        setVariables={setVariables}
        take={take}
        tab={tab}
        history={history}
        region={user?.current_region ? user.current_region : region}
        count={count}
        user={user}
      />
    </React.Fragment>
  );
}
