import React from "react";
import styled from "styled-components";
import { handleFocus, handleBlur } from "utils/forms";
import { FiEyeOff, FiEye } from "react-icons/fi";

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;
  svg {
    position: absolute;
    top: 32px;
    right: 12px;
  }
`;

const Input = ({
  label,
  type = "text",
  handleChange,
  error,
  errors,
  topText,
  ...rest // properties: [value | defaultValue | min | max | name | id | placeHolder]
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  return (
    <InputWrapper id="input-wrapper">
      <div className="input">
        <p className="l">{label}</p>
        <input
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          type={isVisible ? "text" : type}
          {...rest}
        />
      </div>
      {type === "password" &&
        (!isVisible ? (
          <FiEyeOff onClick={() => setIsVisible((isVisible) => !isVisible)} />
        ) : (
          <FiEye onClick={() => setIsVisible((isVisible) => !isVisible)} />
        ))}
      {error ? <p className="error">{error}</p> : ""}
      {errors?.length
        ? errors.map(({ message }, i) => (
            <p key={i} className="error">
              {message}
            </p>
          ))
        : ""}
    </InputWrapper>
  );
};

export default Input;
