import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function StoBooks() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Store & Books</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Where can I buy WRN Hard copies?" triggerTagName="div">
                <p className="text-md my-4">You can buy WRN Hard copies via the following channels:</p>
                <p className="text-md my-4"><span href="/store" className=" mr-2">1.</span>On the WRN Store</p>
                <p className="text-md my-4"><a className="underline font-medium">Visit the store</a></p>
                <p className="text-md my-4"><span className=" mr-2">2.</span>By visiting any of our locations. </p>
                <p className="text-md my-4"><a href="/contact" className="underline font-medium">See our locations</a></p>
                <p className="text-md my-4"><span className=" mr-2">3.</span>Through one of our partner stores.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I cannot find the WRN volume I am looking for" triggerTagName="div">
                <p className="text-md my-4">If you are looking for a specific volume and you cannot find it, please <a href="/contact" className="underline font-medium">contact Us</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I sell my books on WRN website?" triggerTagName="div">
                <p className="text-md my-4">Please, contact us if you wish you have your books on WRN. <a href="/contact" className="underline font-medium">contact Us</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I get discount on books being sold on the WRN website?" triggerTagName="div">
                <p className="text-md my-4">We periodically give discounts on our books. When you visit the store, you would be able to see the books that are on promo.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoBooks;
