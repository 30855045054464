import { stripHtml } from "string-strip-html";

export default function (htmlText, stripTogetherWithTheirContents) {
  if (stripTogetherWithTheirContents && stripTogetherWithTheirContents.length)
    return stripHtml(htmlText, {
      stripTogetherWithTheirContents,
    }).result;
  else return stripHtml(htmlText).result;
}

export function subject_matter_formatter(stringArr) {
  return stringArr.map((str) => {
    if (str.length) {
      if (str[0] === "\u2013") {
        return str.substr(1).trim();
      } else return str.trim();
    }
    return str;
  });
}
