import React from "react";
import { forgotPasswordSchema } from "utils/validation";
import { useFormik } from "formik";
import { AuthContext } from "context/auth";
import { ButtonLoader } from "components/Loaders";
import Input from "components/form/Input";

import styled from "styled-components";
import law from "assets/img/law.jpg";
import '../../assets/css/login.css'

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Content = styled.div`
  padding: 50px;
  h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  & > p {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  max-width: 670px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: white;
  border-radius: 10px;
  @media (max-width: 960px) {
    display: block;
  }
`;

const ImgStyle = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ForgotPassword = () => {
  const { forgotPasswordLoading, forgotPassword } = React.useContext(
    AuthContext
  );
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit() {
      forgotPassword({
        variables: {
          ...values,
        },
      });
    },
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <h2>Forgot your password?</h2>
          <p className="mt-2 max-w-sm">
            Please enter the e-mail address you used when creating your account, we’ll send you a verification code to reset your password.
          </p>

          <form className="auth-form" onSubmit={handleSubmit}>
            <Input
              handleChange={handleChange}
              name="email"
              id="email"
              placeholder=""
              label="Email"
              error={errors?.email}
            />
            <button type="submit" disabled={forgotPasswordLoading}>
              {forgotPasswordLoading ? <ButtonLoader /> : "Send"}
            </button>
          </form>
        </Content>
        <ImgStyle src={law} />
      </Container>
    </Wrapper>
  );
};

export default ForgotPassword;
