import gql from "graphql-tag";

export default gql`
  mutation new_contact_request(
    $category: ContactCategoryId!
    $enquiry: String!
    $enquiry_type: String!
    $name: String!
    $organization: String!
    $phone: String!
    $email: String!
  ) {
    new_contact_request(
      category: $category
      enquiry: $enquiry
      enquiry_type: $enquiry_type
      name: $name
      organization: $organization
      phone: $phone
      email: $email
    ) {
      error {
        message
        path
      }
      message
      ok
      status
    }
  }
`;
