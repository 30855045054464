import React from "react";
import styled from "styled-components";
import Select from "components/form/Select";
import { BlockLoader } from "components/Loaders";
import { CaseContext } from "context/Cases/index";
import parser from "html-react-parser";
import { removeDuplicates } from "utils/functions";
import classnames from "classnames";
import coa from "assets/img/coa.svg";
import floating_icon from "assets/img/floating_icon.png";
import { Link } from "react-router-dom";
import moment from "moment";

const menus = [
  {
    name: "Parties",
    value: "parties",
  },
  {
    name: "Justices",
    value: "justice",
  },
  {
    name: "Facts & History",
    value: "facts",
  },
  {
    name: "Issues",
    value: "issues",
  },
];

const Nav = styled.div`
  background-color: #ffffff;
  padding: 1.125rem 4.5625rem;
  border-bottom: 1px solid #e5e5e5;
  position: sticky;
  top: 15px;

  li {
    cursor: pointer;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1000px) {
      display: none !important;
    }
  }
  select {
    @media (min-width: 1000px) {
      display: none !important;
    }
  }
  @media (max-width: 1000px) {
    padding: 1.125rem 0.5625rem !important;
  }
`;

const TopBar = styled.div`
  margin-top: 40px;
  top: 0px;
`;

const CaseInfo = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 4rem 0;
  padding: 0 10%;
  #parties {
    color: #69c448;
    margin-bottom: 1.25rem;
  }
`;
const Coa = styled.img`
  display: block;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  height: 100px;
`;

const FloatingMenu = styled.img`
  display: none;
  @media (max-width: 1000px) {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    display: block;
  }
`;

const Content = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(199, 237, 187, 0.38);
  margin-top: 1.4375rem;
  border: none;
  border-radius: 5px;
  padding-bottom: 3px;
`;

const RightAlignment = styled.p`
  right: 45px;
  font-size: 1rem;
  font-weight: 400;
  text-align: end;
  font-style: italic;
`;

function readFullJudgement(court_id, id) {
  window.open(
    `${process.env.REACT_APP_DBOARD_URL_}/dashboard/cases/${court_id}/${id}`,
    "_self"
  );
}

export default function Summary({ match }) {
  const [current, setCurrent] = React.useState("parties");
  const [sideBar, setSideBar] = React.useState(false);
  const { getSingleCase, judgement, caseLoading } = React.useContext(
    CaseContext
  );

  React.useEffect(() => {
    getSingleCase(parseInt(match?.params?.case_id));
  }, [getSingleCase, match]);

  React.useEffect(() => {
    const onScroll = (e) => {};

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToSection = (id) => {
    let element = document.getElementById(id);
    setCurrent(id);
    const offset = element.offsetTop;
    window.scroll({
      top: offset,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="content">
      <div className="m-8">
        <div id="judgement-option-1-detail--page">
          <div className="inner-container">
            <Content>
              <TopBar>
                <div id="title-row">
                  <p id="title">
                        {judgement?.case_details?.party_1}{" "}
                        <span className="yellow">vs</span>{" "}
                        {judgement?.case_details?.party_2}
                  </p>
                  <button
                    className="btn"
                    id="button"
                    onClick={() =>
                      readFullJudgement(
                        match?.params?.court_id,
                        match?.params?.case_id,
                      )
                    }
                  >
                    <span>Full Judgement</span>
                  </button>
                </div>
                <Nav id="nav-row">
                  <Select
                    options={[...menus]}
                    name="navigation"
                    onChange={(e) => {
                      scrollToSection(e.target.value);
                    }}
                    value={current}
                    label=""
                    placeholder=""
                  />

                  <ul>
                    {menus?.map((menu, i) => (
                      <li
                        onClick={() => {
                          scrollToSection(menu?.value);
                        }}
                        className={classnames({
                          active: current === menu?.value,
                        })}
                        key={i}
                      >
                        {menu?.name}
                      </li>
                    ))}
                  </ul>
                </Nav>
              </TopBar>
              {caseLoading ? (
                <BlockLoader />
              ) : (
                <div id="content-body">
                  <div id="main-content">
                    <Coa id="coa" src={coa} alt="coat_of_arm" />
                    <FloatingMenu
                      id="floating_icon"
                      src={floating_icon}
                      alt="floating_menu"
                      onClick={() => {
                        setSideBar(true);
                      }}
                    />
                    <CaseInfo id="case-info">
                      <p id="parties" className="green">
                        {judgement?.case_details?.party_1}{" "}
                        <span className="yellow">vs</span>{" "}
                        {judgement?.case_details?.party_2}
                      </p>
                      <p id="case-number">{judgement?.case_details?.volume}</p>
                      <p id="court-type" className="yellow">
                        {judgement?.case_details?.court_id === 1
                          ? "court of appeal"
                          : "supreme court"}
                      </p>
                      <p className="green-text">Between</p>
                      <p id="appelent">
                        {judgement?.case_details?.party_1} - Appellant(s)
                      </p>
                      <p className="other-appelent">
                        {judgement?.case_details &&
                          parser(judgement?.case_details?.party_1_desc)}
                      </p>
                      <p className="green-text">And</p>
                      <p id="respondent">
                        {judgement?.case_details?.party_2} - Respondent(s)
                      </p>
                      <p className="other-respondent">
                        {judgement?.case_details &&
                          parser(judgement?.case_details?.party_2_desc)}
                      </p>
                    <p
                      className="italic text-lg font-bold mt-4"
                      style={{ color: "#cebf09" }}
                    >
                      On {moment(judgement?.case_details?.date * 1000).format("MMMM, Do YYYY")}
                    </p>
                      <RightAlignment className="yellow">
                        {judgement?.case_details?.case_no}
                      </RightAlignment>
                    </CaseInfo>
                    {/* judgement_and_justices */}
                    <div id="case-info-sections">
                      <div className="case-info-section" id="justice">
                        <div className="section-title">
                          <p>Justices</p>
                        </div>
                        <div className="section-body">
                          {removeDuplicates(
                            judgement?.judgement_and_justices,
                            "justice"
                          )?.map((judge, i) => (
                            <div className="section-body-row" key={i}>
                              <p>{judge?.justice}</p>
                              {i === 0 && judge?.judgement_type_id !== 1 && (
                                <p className="sub-text">(Presided)</p>
                              )}
                              {i !== 0 && judge?.judgement_type_id === 1 && (
                                <p className="sub-text">
                                  (Delivered the leading judgment)
                                </p>
                              )}
                              {i === 0 && judge?.judgement_type_id === 1 && (
                                <p className="sub-text">
                                  (Presided and delivered the leading judgment)
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="case-info-section" id="facts">
                        <div className="section-title">
                          <p>Facts &amp; History</p>
                        </div>
                        <div className="section-body">
                          <p>
                            {judgement?.case_details &&
                              parser(
                                judgement?.case_details?.facts_and_history
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="case-info-section" id="issues">
                        <div className="section-title">
                          <p>Issues</p>
                        </div>
                        <div className="section-body">
                          <p>
                            {judgement?.case_details &&
                              parser(judgement?.case_details?.issues || "")}
                          </p>
                          <Link
                            to=""
                            onClick={(e) => {
                              readFullJudgement(
                                match?.params?.court_id,
                                match?.params?.case_id
                              );
                            }}
                            className="rfj-link"
                          >
                            Full Judgement
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Content>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * [extra.menus]
   {
      name: "Held",
      value: "held",
    },
   {
      name: "Judgment",
      value: "judgement",
    },
   {
      name: "Case References",
      value: "case_ref",
    },
   {
      name: "Case History",
      value: "case_history",
    },
   {
      name: "Subject Matter",
      value: "subject-matter",
    },
 */
