import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Payment() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Payments</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What are the different ways to make payment?" triggerTagName="div">
                <p className="text-md my-4">You can make payment using the following ways:</p>
                <p className="text-md my-4"><span className=" mr-2">1.</span>Use your debit or credit card. We support the following cards: Mastercard, Visa Card, Verve Cards</p>
                <p className="text-md my-4"><span className=" mr-2">2.</span>Use your bank login on the app to make payments for subscription. The list of supported banks would be shown.</p>
                <p className="text-md my-4"><span className=" mr-2">3.</span>Make a transfer. This option does not require you to log your card details on WRN. When you select this option, an account number would be generated for you, where you would be able to make a transfer from your own bank account.</p>
                <p className="text-md my-4"><span className=" mr-2">4.</span>USSD: When you select this option, you would select your bank, and we would generate USSD codes for you. You would use this USSD code to make a payment. This option does not require you to register your card details on WRN.</p>
                <p className="text-md my-4"><span className=" mr-2">5.</span>Visa QR: Use this option if you make use of the Visa QR code to make purchases. We would generate a unique QR code for you, and you would scan the code to make payment.</p>
                <p className="text-md my-4">You would immediately get a mail when you make a successful payment</p>
                <p className="text-md my-4">We encourage all customers to make payment using their debit or credit cards. In doing so, your account is automatically activated.</p>
                <p className="text-md my-4">In event that you make a non-card payment, and you are unsure of the statue, please, send mail to: <a href="mailto:payments@weeklyreportsofngeria.com" className="underline font-medium">payments@weeklyreportsofngeria.com</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="My payment was declined, what do I do?" triggerTagName="div">
                <p className="text-md my-4">If you get a declined payment error, please confirm that the payment has not been deducted from your bank account.</p>
                <p className="text-md my-4">If your account was debited, please notify your bank for reversal.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Where can I see all the payments that I have made?" triggerTagName="div">
                <p className="text-md my-4">To see all your transactions, go to the transactions menu on your dashboard, from your Account settings.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I was debited more than once for a single payment." triggerTagName="div">
                <p className="text-md my-4">If you were debited more than once for a single payment, please contact your bank.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What cards can I use to make payment?" triggerTagName="div">
                <p className="text-md my-4">We accept the following cards: Mastercard, Visa card, Verve card.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
