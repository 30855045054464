import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SearchContext } from "context/search";
import icons from "assets";
import { AuthContext } from "../../context/auth";

function runSearch(history, tab, search) {
    history.push(`/search?q=${search}&tab=${tab}`);
}

export default function Top() {
    const history = useHistory();
    const { tab } = React.useContext(SearchContext);
    const [search, setSearch] = React.useState("");

    const { isAuthenticated } = useContext(AuthContext);
    const token = localStorage.getItem("wrn_user_dashboard_token") || "";
    return (
        <React.Fragment>
            <div className="my-6 mx-6">
                <form
                    className="mx-auto max-w-2xl shadow-lg rounded-lg flex"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <input
                        type="text"
                        className="w-full rounded-lg py-4 px-4 border-2 border-yellow-600"
                        placeholder="Search for judgments, indexes and other volumes..."
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onKeyUp={(e) => {
                            if (e.key === "Enter" && search) {
                                runSearch(history, tab, search);
                            }
                        }}
                    />
                    <button
                        onClick={() => {
                            if (search) runSearch(history, tab, search);
                        }}
                        className="py-4 px-6 custom-bg-green rounded-r-lg rounded-l-none -ml-2"
                    >
                        <img alt={"image"} src={icons.search} />
                    </button>
                </form>
            </div>
            <div className="mx-20 flex m-x-8 blo-ck items-center my-8">
                <div className="w-1/2 full-width">
                    <div className="mx-auto">
                        <p className="custom-text-green text-2xl max-w-lg res-head-txt font-bold">
                            Intelligent Law Reporting and Legal Research backed
                            by Technology
                        </p>
                        <div className="custom-left-width p-5 my-10">
                            <p className="font-medium max-w-lg">
                                The easiest way to search for Supreme Court and
                                Court of Appeal Judgments, precedents and ratios
                                is with Weekly Reports of Nigeria.
                            </p>
                        </div>

                        <div className="-mx-3 flex bock my-4 items-center blo-ck">
                            {!isAuthenticated ? (
                                <button
                                    onClick={() => history.push("/features")} // => Free-Trial
                                    className="p-3 w-48 custom-bg-green text-white text-center shadow rounded-lg font-bold mx-3"
                                >
                                    SPIDEL DISCOUNT
                                </button>
                            ) : (
                                <button
                                    onClick={() =>
                                        window.open(
                                            `${process.env.REACT_APP_DBOARD_URL_}/login/${token}`,
                                            "_self"
                                        )
                                    }
                                    className="p-3 w-48 custom-bg-green text-white text-center shadow rounded-lg font-bold mx-3"
                                >
                                    Go to Dashboard
                                </button>
                            )}

                            <button
                                onClick={() => history.push("/contact")}
                                className="p-3 w-48 butt-top my-4 border bg-white text-center custom-border rounded-lg custom-text-green font-bold mx-3"
                            >
                                Speak with us
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="w-1/2 full-width my-10"
                    onClick={() => history.push("/features")}
                    style={{
                        cursor: "pointer",
                    }}
                >
                    <img
                        className="ml-auto m-x-auto first"
                        src="https://res.cloudinary.com/ategsoft-technologies/image/upload/v1621443748/SPIDEL/WRN_Spidel_promo_design_800.png" // previous: https://res.cloudinary.com/dudhmrdin/image/upload/v1608982072/WRN_web_landing_1_qgfzfc.png
                    />
                </div>
            </div>
            <hr className="border border-gray-300" />
        </React.Fragment>
    );
}
