import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: ${({ height }) => height || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-display: column;
  flex-direction: column;
  p {
    color: #89879c !important;
    font-size: 0.9rem;
    line-height: 1.7;
    text-align: center;
    max-width: 275px;
    line-height: 1.7;
    margin-top: 15px;
  }
`;

const EmptyState = ({ text, img, height, header }) => {
  return (
    <Wrapper height={height}>
      <img src={img} />
      <h4>{header}</h4>
      <p>{text}</p>
    </Wrapper>
  );
};

export default EmptyState;
