import React from "react";
import x from "assets/x.svg";
import Book from "assets/Book1.png";

function Cart() {
  return (
    <div className="content">
      <div className="mx-8 my-8 blo-ck flex items-center">
        <p className="font-bold text-2xl custom-text-green">Store Cart (1)</p>
        <button className="py-3 px-6 top-2 custom-bg-green text-white font-bold ml-auto">
          Proceed to Checkout
        </button>
      </div>
      <div>
        <div className="flex blo-ck mx-8 ml-reset my-16">
          <div className="none">
            <button>
              <img className="w-8" src={x} />
            </button>
          </div>
          <div className="flex blo-ck mx-8">
            <div>
              <img src={Book} />
            </div>
            <div className="ml-8 ml-reset full-width top">
              <p className="text-2xl custom-text-green font-bold">
                Nigerian Human Rights Law Reports
              </p>
              <p className="text-2xl custom-text-yellow font-bold my-8">
                NGN 1,500
              </p>
              <div className="flex my-4">
                <button className="py-3 px-4 bg-white border border-gray-200">
                  -
                </button>
                <p className="py-3 px-4 bg-white border border-gray-200">1</p>
                <button className="py-3 px-4 bg-white border border-gray-200">
                  +
                </button>
              </div>
              <button className="py-3 px-4 hidden bg-red-100 text-red-800 font-bold rounded-lg top x">
                Remove
              </button>
            </div>
          </div>
        </div>
        <div className="p-4 rounded-lg bg-gray-50 flex blo-ck items-center my-10 mx-8">
          <form className="flex blo-ck max-w-lg">
            <label className="custom-text-green font-bold text-sm">
              Discount Code
            </label>
            <input
              className="w-full py-2 ml-reset px-4 top-2 w-full bg-white rounded-lg"
              type="text"
              placeholder="Code"
            />
          </form>
          <p className="font-lg mx-4 text-black ml-auto top font-medium">
            Subtotal<b className="custom-text-green ml-4">NGN 3,000</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Cart;
