import gql from "graphql-tag";

export default gql`
  query judgements_list(
    $court_category: Int!
    $recent: Recent
    $options: read_options
    $region: Region!
  ) {
    judgements_list(
      court_category: $court_category
      region: $region
      recent: $recent
      options: $options
    ) {
      ... on judgements_list {
        data {
          case_id
          court_id
          case_no
          party_1
          party_2
          title
          section
          description
          formatted_date
        }
      }
    }
  }
`;
