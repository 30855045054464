import gql from "graphql-tag";

export default gql`
  query getSubs($role: FeaturedRole!) {
    getSubs(role: $role) {
      subscription_plan_id
      name
      domain
      description
      active
      provider
      plan_code
      amount
      interval
      feature_list
      featured_roles
      sub_plan_class {
        sub_plan_class_id
        name
        description
      }
    }
  }
`;

export const GET_SUB = gql`
  query subscription_plan($id: Int!) {
    subscription_plan(id: $id) {
      name
      plan_code
    }
  }
`;

export const PURCHASE_SUBSCRIPTION = gql`
  query purchase_subscription(
    $info: purchase_info!
    $provider: SubscriptionProviders!
  ) {
    purchase_subscription(info: $info, provider: $provider) {
      ok
      status
      message
      data {
        access_code
        authorization_url
        reference
      }
      error {
        message
        path
      }
    }
  }
`;
