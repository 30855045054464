import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

export default function FeaturesTab({ setRole, role }) {
  return (
    <div className="flex about-link mx-auto">
      <Link
        to=""
        onClick={(e) => {
          e.preventDefault();
          setRole(2);
        }}
        className={classnames(
          "py-4 tab-fea text-gray-800 border font-medium bg-gray-100",
          {
            "custom-border-bot2": role === 2,
          }
        )}
      >
        Lawyer
      </Link>
      <Link
        to=""
        onClick={(e) => {
          e.preventDefault();
          setRole(3);
        }}
        className={classnames(
          "py-4 tab-fea text-gray-800 border font-medium bg-gray-100",
          {
            "custom-border-bot2": role === 3,
          }
        )}
      >
        Law University
      </Link>
      <Link
        to=""
        onClick={(e) => {
          e.preventDefault();
          setRole(5);
        }}
        className={classnames(
          "py-4 tab-fea text-gray-800 border font-medium bg-gray-100",
          {
            "custom-border-bot2": role === 5,
          }
        )}
      >
        Law School
      </Link>
    </div>
  );
}
