import search from "assets/search.svg";
import first from "assets/first.svg";
import nanda from "assets/nanda.png";
import exvn from "assets/exvn.png";
import appstore from "assets/appstore.svg";
import playstore from "assets/playstore.svg";
import sec from "assets/sec.png";
import down from "assets/down.svg";
import itw from "assets/itw.svg";
import icon1 from "assets/icon1.svg";
import icon2 from "assets/icon2.svg";
import icon3 from "assets/icon3.svg";
import arreft from "assets/arreft.svg";
import arright from "assets/arright.svg";

export default {
  search,
  first,
  nanda,
  exvn,
  appstore,
  playstore,
  sec,
  down,
  itw,
  icon1,
  icon2,
  icon3,
  arreft,
  arright,
};
