import React from "react";
import styled from "styled-components";
import "assets/css/dictionary.css";

const Content = styled.div`
  padding: 1rem 2.375rem;
  margin: 2.3125rem 0;
  border: none;
  border-radius: 5px;
`;

export default function DictionaryTab({ data }) {
  return (
    <Content>
      <section>
        {data?.map(({ definition, id, word }) => (
          <div className="di-con-wrap" key={id}>
            <div className="di-con-textbox">
              <div className="flex align-center">
                <p className="di-con-head">{word}</p>
                <button className="ml-auto">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 3H7C5.9 3 5.01 3.9 5.01 5L5 21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V5H17V18Z"
                      fill="#69C448"
                    />
                  </svg>
                </button>
              </div>
              <div
                className="flex align-center bock"
                style={{ marginTop: "20px" }}
              >
                <p className="di-con-text">{definition}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Content>
  );
}
