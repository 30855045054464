import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Versions() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">WRN Versions</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How many versions of WRN are there?" triggerTagName="div">
                <p className="text-md my-4">There are three (3) versions of WRN</p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Online:</b>The web version of weekly Reports of Nigeria. WRN Online is the standard default WRN version. It is accessiblevia a web browser. It can also be viewed on mobile browsers. <a href="/features" className="underline font-medium">Subscribe to WRN Online</a></p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Offline:</b>This is the offline version of Weekly Reports of Nigeria. This version is installed on desktops or laptops. Once the Offline version is installed, the customer gets access to cases, judgment, constitution and other related content.</p>
                <p className="text-md my-4"><b className="mr-2 underline custom-text-green">WRN Mobile:</b>This is the mobile version of Weekly Reports of Nigeria. To access this version, you need to download it from the google play store and the apple store.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Do the subscription price vary per WRN Version?" triggerTagName="div">
                <p className="text-md my-4">Each WRN version has its own subscription</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Is there any free version of WRN?" triggerTagName="div">
                <p className="text-md my-4">We have a 1 Month period in which users can make use of WRN for free</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I get the Enterprise Edition?" triggerTagName="div">
                <p className="text-md my-4">Our Enterprise Edition is for managing multiple subscriptions under one account. For example, a team of five (5) lawyers. This allows you to add users as you desire to your platform.</p>
                <p className="text-md my-4">You can also remove users as you desire. All the users who you add can proceed to use WRN on any of their devices.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Versions;
