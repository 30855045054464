import React from "react";
import styled from "styled-components";
import image404 from "assets/img/404.png";
import { Button } from "components/Styles";
import { FiArrowLeft } from "react-icons/fi";

const Wrapper = styled.div`
  height: ${({ height }) => height || "100vh"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-display: column;
  flex-direction: column;
  padding: 10px;
  p {
    color: #27750a !important;
    font-size: 0.9rem;
    line-height: 1.7;
    text-align: center;
    max-width: 450px;
    line-height: 1.7;
    margin: 15px 0 25px;
  }
  img {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Error404 = ({ history }) => {
    return (
        <Wrapper>
            <img src={image404} />
            <h4>Whoops!!!</h4>
            <p>Sorry, we are not able to find what what you were looking for.....</p>
            <Button
                className="flex items-center"
                onClick={() => {
                    history.goBack();
                    // window.location.reload();
                }}
            >
                <FiArrowLeft style={{ marginRight: "20px" }} />
                Go Back
            </Button>
        </Wrapper>
    );
};

export default Error404;
