import React, { useState, useContext } from "react";
import styled from "styled-components";
import logo from "assets/logo/logo.png";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import nav_profile from "assets/navprofile.svg";
// import cart from "assets/cart.svg";
import "assets/css/sidebar.css";
import { AuthContext } from "../context/auth";
import UserAvatar from "react-user-avatar";
import { ArrowRightCircle, LogOut, Settings } from "react-feather";

const BurgerButton = styled.span`
  position: relative;
  top: 30px;
  left: 10px;
  font-size: 20px;
  z-index: 1;
  visibility: hidden;
  border: 3px solid red;
  @media (max-width: 960px) {
    visibility: visible;
  }
`;

const SideBarFooter = styled.div`
  position: absolute;
  bottom: 0;
  background-color: #e4e9f1;
  width: 100%;
  height: 64px;
  padding: 1em 0;
  text-align: center;
  cursor: pointer;
  display: none;
  @media (max-width: 960px) {
    display: block;
  }
`;

const SideBarButton = styled.div`
    a {
      display: flex;
      text-align: center;
      align-items: center;
      margin: 0 0.5rem;
      font-size: 1rem;
      color: #fff;
      background-color: #27750a;
      border: none;
      width: 80%
      border-radius: 5px;
   }

   .options {
     marginLeft: 2rem
    position: relative;
    left: 10px;
    bottom: 5px;
    font-size: 35px;
    padding: 4px;
    :hover {
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.2);
    }
}
`;

const Popover = styled.span`
  position: absolute;
  background-color: white;
  width: 89%;
  right: 15px;
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const SingleOption = styled.div`
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 0.7em;
  padding-top: 0.7rem;
  color: #333333;
  :hover {
    background-color: #eee;
    color: #27750a;
    cursor: pointer;
  }
`;

const displayPopover = (e) => {
  // setIsOpen(!isOpen);
  e.currentTarget.parentElement.nextElementSibling.classList.toggle(
    "invisible"
  );
};

const Sidebar = () => {
  const [toggle, setToggle] = useState(false);

  // this makes the sidebar opens or closes based on window resize
  window.addEventListener("resize", () => {
    const buttonToggle = document.querySelector(".burger");
    const sidebar = document.querySelector(".sidebar");

    if (window.innerWidth < 960) {
      buttonToggle.style.visibility = "visible";
    }
    if (window.innerWidth < 960 && sidebar.classList.contains("open")) {
      // sidebar.classList.add('open')
      // sidebar.classList.remove('close')
    }
    if (window.innerWidth > 960) {
      if (sidebar) {
        sidebar.classList.remove("open");
        buttonToggle.style.visibility = "hidden";
      }
    }
  });

  const closeSideBar = () => {
    const sidebar = document.querySelector(".sidebar");
    const main = document.querySelector(".main");
    const buttonToggle = document.querySelector(".burger");
    if (sidebar) {
      sidebar.classList.add("close");
      sidebar.classList.remove("open");
      buttonToggle.style.visibility = "visible";
    }
  };

  const openSidebar = () => {
    const sidebar = document.querySelector(".sidebar");
    const main = document.querySelector(".main");
    const buttonToggle = document.querySelector(".burger");
    if (sidebar) {
      sidebar.classList.add("open");
      sidebar.classList.remove("close");
      buttonToggle.style.visibility = "hidden";
    }
    // if (main) {
    //   main.style.marginLeft = "17em";
    // }
    // if (buttonToggle) {
    //   buttonToggle.style.visibility = "hidden";
    // }
  };

  const [activeOption, setActiveOption] = useState({
    text: "Cases",
    url: "/case",
  });

  const popoverOptions = [
    {
      text: "Cases",
      url: "/case",
    },
    {
      text: "Headnotes",
      url: "/headnotes",
    },
    {
      text: "Subject Matter",
      url: "/subject-matter",
    },
    {
      text: "Ratio Decidendi",
      url: "/ratio-decidendis",
    },
  ].filter((option) => option.text !== activeOption.text);

  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  const { isAuthenticated, me, logout, meQuery } = useContext(AuthContext);

  return (
    <React.Fragment>
      <aside style={{ height: "100%" }} className="sidebar">
        <header>
          <Link to="/" onClick={closeSideBar}>
            <img src={logo} alt="logo" className="sidebar__logo" />
          </Link>
        </header>
        {!isAuthenticated && (
          <nav className="sidebar-nav">
            <Link
              to="/about"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              About Us{" "}
            </Link>
            <Link
              to="/features"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              Features{" "}
            </Link>
            <Link
              to="/support"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              Support{" "}
            </Link>
            <Link
              to="/contact"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              Contact{" "}
            </Link>
            <div className="items-center p-4" style={{ display: "flex" }}>
              <Link
                to="/login"
                className="font-medium"
                onClick={closeSideBar}
                style={{ color: "#27750a" }}
              >
                Sign in{" "}
              </Link>
              <Link
                to="/register"
                className="font-semibold ml-auto custom-border rounded-lg"
                onClick={closeSideBar}
                style={{
                  color: "#27750a",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Sign up{" "}
              </Link>
            </div>
            {/*<Link to="/store">Store </Link>*/}
            {/*<Link className="diff" to="/">*/}
            {/*  Free Trial{" "}*/}
            {/*</Link>*/}
            {/*<Link style={{ display: "flex" }} to="/cart">*/}
            {/*  <img src={cart} /> <span style={{ marginLeft: "1rem" }}>Cart </span>*/}
            {/*</Link>*/}
          </nav>
        )}
        {isAuthenticated && (
          <nav className="sidebar-nav">
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                closeSideBar();
              }}
              className="items-center border-b border-gray-300"
              style={{ display: "flex" }}
            >
              <UserAvatar
                size="40"
                style={{
                  color: "white",
                  fontWeight: "400",
                  userSelect: "none",
                  marginRight: "5px",
                  border: "8px solid #ffebf4",
                  borderRadius: "50%",
                  width: "55px",
                }}
                name={`${me?.first_name} ${me?.last_name}`}
                src={me?.profile_pic ? me?.profile_pic : nav_profile}
              />

              <div style={{ marginLeft: 10 }}>
                <p style={{ fontSize: 14, fontWeight: "400" }}>Signed in as</p>
                <p style={{ fontSize: 14, fontWeight: "700" }}>
                  {me?.first_name}
                </p>
              </div>
            </Link>
            <Link
              to="/about"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              About Us{" "}
            </Link>
            <Link
              to="/features"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              Features{" "}
            </Link>
            <Link
              to="/support"
              className="border-b border-gray-300"
              onClick={closeSideBar}
            >
              Support{" "}
            </Link>
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_DBOARD_URL_}/login/${token}`,
                  "_self"
                );
              }}
              className="items-center border-b border-gray-300"
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "10px" }}>
                <ArrowRightCircle />
              </div>
              Go to dashboard{" "}
            </Link>
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_DBOARD_URL_}/login/${token}?redirectUrl=dashboard.settings`,
                  "_self"
                );
              }}
              className="items-center border-b border-gray-300"
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "10px" }}>
                <Settings />
              </div>
              Settings{" "}
            </Link>
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                logout({ variables: { type: "" } });
              }}
              className="items-center border-b border-gray-300"
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "10px" }}>
                <LogOut />
              </div>
              Logout{" "}
            </Link>
            {/* <Link to="/login" className="text-green-700" onClick={closeSideBar}>Logout </Link> */}
            {/*<Link to="/store">Store </Link>*/}
            {/*<Link className="diff" to="/">*/}
            {/*  Free Trial{" "}*/}
            {/*</Link>*/}
            {/*<Link style={{ display: "flex" }} to="/cart">*/}
            {/*  <img src={cart} /> <span style={{ marginLeft: "1rem" }}>Cart </span>*/}
            {/*</Link>*/}
          </nav>
        )}
        <SideBarFooter onClick={closeSideBar}>
          <MdKeyboardArrowLeft size={30} />
        </SideBarFooter>
      </aside>
      <BurgerButton
        style={{ marginBottom: "4rem" }}
        className="burger ml-auto mr-4"
        onClick={openSidebar}
      >
        <GiHamburgerMenu />
      </BurgerButton>
    </React.Fragment>
  );
};

export default Sidebar;
