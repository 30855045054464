import React from "react";
import third from "assets/third.png";
import fourth from "assets/fourth.png";
import appstore from "assets/appstore.svg";
import playstore from "assets/playstore.svg";
import send from "assets/send.svg";
import { Link } from "react-router-dom";
import comma from "assets/comma.svg";

function About() {
  return (
    <div className="content">
      <div className="bg-gray-100 mx-8 blo-ck flex my-8">
        <div className="about-head px-10 full-width w-1/2 none">
          <div className="my-20">
            <p className="text-white font-bold text-2xl my-4">About WRN</p>
            <div className="my-10">
              <p className="text-white font-semibold text-xl">Best Value</p>
              <p className="text-white font-semibold text-xl my-2 ar-text">
                Legal Research
              </p>
              <p className="text-white font-semibold text-xl">Platform</p>
            </div>
          </div>
        </div>
        <div className="w-1/2 full-width p-10">
          <img className="mx-auto" style={{ width: '300px' }} src={third} />
        </div>
      </div>
      <div className="flex items-center border-b border-gray-500 mx-8 about-link">
        <Link
          to="/about"
          className="py-3 px-5 text-black border-b-2 border-yellow-500 font-semibold text-xl"
        >
          About WRN
        </Link>
        <Link
          to="/about/team"
          className="py-3 px-5 text-gray-600 font-semibold text-xl"
        >
          Our Team
        </Link>
        <Link className="py-3 px-5 text-gray-600 font-semibold text-xl">
          Our Story
        </Link>
        <Link className="py-3 px-5 text-gray-600 font-semibold text-xl">
          WRN Advantage
        </Link>
      </div>
      <div className="bg-white mx-8 blo-ck flex my-16">
        <div className="w-3/5 full-width">
          <p className="custom-text-green text-2xl font-bold my-6">
            Weekly Reports of Nigeria
          </p>
          <p className="text-gray-600 text-lg max-w-4xl my-4">
            Weekly Report of Nigeria (WRN) is a weekly collection of court
            judgements in Nigeria. WRN is backed by advanced technology which
            helps reduce time and increase efficiency, thereby ensuring that you
            complete your legal reseaarch without stress. WRN is built on over
            20 years editorial experience in delivering law reports in West
            Africa.
          </p>
          <p className="text-gray-600 text-lg max-w-4xl my-4">
            We have over 40,000 judgments of the supreme court and Court of
            Appeal. We have 25,000 ratios, detailed Laws of Federation, Civil
            Procedure Rules, State Laws, High Court Rules, Constitution,
            Journals, books, and other titles. We are your one-stop shop for
            legal research. WRN is a product of Legaltext Publishing Company
            Limited.
          </p>
        </div>
        <div className="w-1/4 ml-20 full-width ml-top">
          <p className="p-2 border-b-2 mb-2 border-gray-200 font-bold custom-text-yellow">
            QUICK LINKS
          </p>
          <div className="p-2 border-b-2 mb-2 border-gray-200 w-full">
            <Link className="text-gray-700 font-bold">Compare WRN Versions</Link>
          </div>
          <div className="p-2 border-b-2 mb-2 border-gray-200 w-full">
            <Link className="text-gray-700 font-bold">Search for Judgments</Link>
          </div>
          <div className="p-2 border-b-2 mb-2 border-gray-200 w-full">
            <Link className="text-gray-700 font-bold">Offline Version</Link>
          </div>
          <div className="p-2 border-b-2 mb-2 border-gray-200 w-full">
            <Link to="/store" className="text-gray-700 font-bold">Visit our Store</Link>
          </div>
          <div className="p-2 border-b-2 mb-2 border-gray-200 w-full">
            <Link to="/support" className="text-gray-700 font-light">
              <b className="font-bold mr-4">Support?</b> Call 0700-WRN-0000
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 mx-8 flex blo-ck items-center my-16">
        <div className="w-1/2 full-width p-8">
          <img className="mx-auto" src={fourth} />
        </div>
        <div className="w-1/2 full-width p-8">
          <div className="mx-auto">
            <p className="text-gray-600 text-lg flex my-4">
              <img src={comma} className="-mt-32 n-one no-ne none -ml-12" />
              WRN is one of the leading law reporting platforms in Nigeria. I am
              particularly glad that they have both hardcopy and softcopy. My
              firm has been using WRN for many years now, and with these new
              improvements, our loyalty is renewed.
            </p>
            <p className="text-green-600 font-bold text-sm mt-8">
              Feyi Jubril, SAN
            </p>
            <p className="custom-text-green font-light text-sm my-2">
              Partner, Jubril and Jubril
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white mx-8 my-16">
        <p className="text-center my-10 text-green-800 font-bold text-2xl">
          WRN Features
        </p>
        <div className="flex blo-ck -mx-2">
          <div className="mx-2 ml-top full-width w-1/3 shadow-md bg-white p-8 rounded-lg">
            <p className="text-xl text-center custom-text-yellow font-bold">
              Case Law Search
            </p>
            <p className="text-gray-600 text-lg max-w-sm text-center my-4">
              Search through WRN for cases, judgments, precedents, ratios that
              traditional search cannot find.
            </p>
          </div>
          <div className="mx-2 ml-top full-width w-1/3 shadow-md bg-white p-8 rounded-lg">
            <p className="text-xl text-center custom-text-yellow font-bold">
              Intuitive Dashboard
            </p>
            <p className="text-gray-600 text-lg max-w-sm text-center my-4">
              Enjoy Smart text selection, get insights on judgments, words in
              gold, and ratios from the dashboard.
            </p>
          </div>
          <div className="mx-2 ml-top full-width w-1/3 shadow-md bg-white p-8 rounded-lg">
            <p className="text-xl text-center custom-text-yellow font-bold">
              Add Case Notes
            </p>
            <p className="text-gray-600 text-lg max-w-sm text-center my-4">
              Add notes to help with your research, as you move along. Start a
              research and continue from where you left off.
            </p>
          </div>
        </div>
        <div className="mx-auto text-center p-12 p-reset">
          <Link to="/features" className="py-3 px-8 custom-bg-green text-xl font-bold mob-but-text rounded-md text-white">
            Explore all WRN Features
          </Link>
        </div>
      </div>
      <div className="bg-white mx-8 my-16">
        <div className="flex blo-ck bock -mx-2">
          <div className="mx-2 w-1/3 full-w-idth full-width rounded-lg bg-gray-100 ml-reset p-8">
            <p className="text-center mb-4 custom-text-yellow font-bold text-xl">
              Download WRN Offline
            </p>
            <p className="text-gray-600 text-lg max-w-sm text-center my-4 mi-d-d">
              Offline access to judgments, LFN, Rules of Court and other
              Volumes.
            </p>
            <div className="mx-auto text-center my-8">
              <Link to="/features" className="py-3 px-8 custom-bg-green text-xl mob-but-text rounded-lg font-bold text-white">
                Explore all WRN Features
              </Link>
            </div>
          </div>
          <div className="mx-2 w-1/3 full-w-idth full-width ml-reset rounded-lg p-8">
            <div className="my-6">
              <div className="w-48 mx-auto">
                <img src={appstore} />
              </div>
              <div className="mt-8 w-48 mx-auto">
                <img src={playstore} />
              </div>
            </div>
          </div>
          <div className="mx-2 w-1/3 full-w-idth full-width rounded-lg custom-bg-green p-8 ml-reset">
            <p className="text-center mb-4 text-white font-bold text-xl">
              Get WRN Articles
            </p>
            <p className="text-white text-lg max-w-sm text-center my-4 mi-d-d">
              WRN Articles and Judgment summaries delivered to your mail.
            </p>
            <div className="my-6">
              <form className="mx-auto max-w-2xl flex">
                <input
                  type="text"
                  className="w-full rounded-lg py-4 px-4 custom-border-yellow"
                  placeholder="Enter Email..."
                />
                <button className="py-4 px-6 custom-bg-yellow rounded-r-lg rounded-l-none -ml-2">
                  <img src={send} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
