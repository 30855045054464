import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "context/auth";
import { isTokenValid } from "utils/auth";

export default function AuthRoute({
  layout: Layout,
  component: Component,
  auth,
  title,
  ...rest
}) {
  const { isAuthenticated, setIsAuthenticated } = React.useContext(AuthContext);
  useEffect(() => {
    if (!localStorage.wrn_user || !isTokenValid()) {
      localStorage.removeItem("wrn_user");
      localStorage.removeItem("wrn_user_dashboard_token");
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated)
          return (
            <Layout title={title}>
              <Component {...props} />
            </Layout>
          );
        else return <Redirect to="/" />;
      }}
    />
  );
}
