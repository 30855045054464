import React from 'react'
import styled from "styled-components";
import cards from 'assets/img/cards.svg'
import visa from 'assets/img/visa.svg'
import { Link } from "react-router-dom";

const Wrapper = styled.div`
    max-width: 700px;
    background-color: #F6f6f6;
    padding: 20px 0;
    border-radius: 10px;
    margin: 40px auto;

    @media (max-width: 600px) {
        border-radius: 0px;
        margin: auto;
        background-color: #FFF;
    }
`;
const Content = styled.div`
    padding: 30px 20px;
`;
const Card = styled.div`
    padding: 25px;
    background: linear-gradient(180deg, #9F90CD 0%, #1E0D52 100%);
    border-radius: 10px;
    max-width: 375px;
    margin: auto;

    @media (max-width: 400px) {
        margin: 0px 20px;
    }
`;
const Form = styled.div`
    width: 100%;
    padding: 30px 20px;
`;

export default function PaymentCard() {
    return (
        <Wrapper>
            <div id="pc-head">
                <Link className="pc-ti1">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.0054 12H5.00537" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.0054 19L5.00537 12L12.0054 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p style={{ color: '#27750A', fontSize: 24, fontWeight: '600', marginLeft: 10 }}>Pay with Card</p>
                </Link>
                <div className="pc-ti2">
                    <img src={cards} />
                </div>
            </div>
            <Content>
                <p style={{ color: '#27750A', fontSize: 18, fontWeight: '400', marginLeft: 10 }}>Pay NGN 2,500</p>
            </Content>
            <Card>
                <div id="pc-card">
                    <img src={visa} />
                    <p id="pc-num">4829 0921 4352 4457</p>
                    <div id="pc-card-foot">
                        <div className="">
                            <p style={{ color: 'rgba(255, 255, 255, 0.5)', textTransform: 'uppercase', fontSize: 10, marginBottom: 5 }}>Cardholder name</p>
                            <p style={{ color: '#FFF', fontSize: 15 }}>John Doe</p>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <p style={{ color: 'rgba(255, 255, 255, 0.5)', textTransform: 'uppercase', fontSize: 10, marginBottom: 5, textAlign: 'right' }}>Cardholder name</p>
                            <p style={{ color: '#FFF', fontSize: 15, textAlign: 'right' }}>05/21</p>
                        </div>
                    </div>
                </div>
            </Card>
            <Form>
                <div style={{ marginTop: 20, position: 'relative' }}>
                    <label id="pc-input-head">Cardholder Name</label>
                    <input type="text" id="pc-input" />
                </div>
                <div style={{ marginTop: 20, position: 'relative' }}>
                    <label id="pc-input-head">Card Number</label>
                    <input type="text" id="pc-input" />
                </div>
                <div id="row-input">
                    <div style={{ position: 'relative', marginRight: 20 }}>
                        <label id="pc-input-head">Expiry Date</label>
                        <input type="text" id="pc-input" />
                    </div>
                    <div style={{ position: 'relative' }} id="row-i-2">
                        <label id="pc-input-head">CVV</label>
                        <input type="text" id="pc-input" />
                    </div>
                </div>
                <div id="pc-button">
                    <button type="submit" id="pc-form-sub">Pay NGN 2,500</button>
                </div>
            </Form>
        </Wrapper>
    )
}
