import React from "react";
import { toast } from "react-toastify";
import { IoIosCheckmark } from "react-icons/io";

export const Success = ({ message }) => (
  <div>
    <IoIosCheckmark style={{ fontSize: "2rem", marginRight: "10px" }} />
    <span>{message}</span>
  </div>
);

export const Error = ({ message }) => (
  <div>
    <span style={{ marginRight: "10px" }}>{message}</span>
  </div>
);

const Toast = (msg, type, lessImportant) => {
  switch (type) {
    case "success":
      toast.success(<Success message={msg} />, {
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
      break;
    case "error":
      toast.error(<Error message={msg} />, {
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
      });
      break;
    case "reg.success":
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
      });
      break;
    case "info":
    default:
      toast.info(msg, {
        position: lessImportant
          ? toast.POSITION.BOTTOM_RIGHT
          : toast.POSITION.TOP_CENTER,
      });
  }
};

export default Toast;
