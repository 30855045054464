import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Work() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Work With Us</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I get to work with WRN?" triggerTagName="div">
                <p className="text-md my-4">Legaltext is the parent company of Weekly Reports of Nigeria. We welcome innovative and creative thinkers to join our ever growing team.</p>
                <p className="text-md my-4">Please, contact us to let us know in what capacity you would like to work with us.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I be a marketer for WRN?" triggerTagName="div">
                <p className="text-md my-4">We have room for client and registered marketers. Every client is a potential marketer, and can start earning commissions as soon as they become clients</p>
                <p className="text-md my-4">To be a registered or independent marketer, please contact us.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I be an article contributor?" triggerTagName="div">
                <p className="text-md my-4">Becoming a contributor with WRN is as simple as contacting us.</p>
                <p className="text-md my-4">Send us a message today, stating your intention and which categories you would like to contribute to. In your message, state the following: Name, email, phone number, Chamber / Work Place.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
