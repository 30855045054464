import { uniqBy } from "lodash";

export const removeDuplicates = (data, field) => {
  return uniqBy(data, field);
};

export const stripText = (str, length = 120) => {
  return `${str.substring(0, length)}........`;
};

export const generateString = (length = 5) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
