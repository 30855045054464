import React, { createContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import notify from "utils/toast";
import { GET_JUDGEMENT_LIST, GET_CASES } from "schema/judgement";

export const CaseContext = createContext({});

const CaseContextProvider = ({ children }) => {
  const [getJudgementList, { loading, data }] = useLazyQuery(
    GET_JUDGEMENT_LIST
  );

  const [getCase, { loading: caseLoading, data: judgementCase }] = useLazyQuery(
    GET_CASES
  );
  const [judgement, setJudgement] = React.useState({});

  React.useEffect(() => {
    if (judgementCase) {
      const {
        case_details: { data, error },
      } = judgementCase;
      if (error) {
        if (error) {
          error.forEach((err) => {
            notify(err.message, "error");
          });
        }
      } else {
        setJudgement(data);
      }
    }
  }, [judgementCase]);

  const getSingleCase = useCallback(async (id) => {
    getCase({
      variables: {
        case_id: id,
        region: localStorage.country_code || "ng",
      },
    });
  }, []);

  return (
    <CaseContext.Provider
      value={{
        getJudgementList,
        loading,
        data,
        caseLoading,
        judgement,
        getSingleCase,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};

export default CaseContextProvider;
