import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function Commissions() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Commissions</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Can I earn commissions on WRN?" triggerTagName="div">
                <p className="text-md my-4">You can earn commissions on WRN. The following are the commission categories:</p>
                <p className="text-md my-4"><span className=" mr-2">1.</span>Earn commission as a Marketer. <a href="/contact" className="underline font-medium">Contact us</a> about this</p>
                <p className="text-md my-4"><span className=" mr-2">2.</span>Earn commissions as a customer when you refer new customers to WRN. Your commissions are added to your WRN account, and are used to offset your subscriptions</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I become a marketer for WRN?" triggerTagName="div">
                <p className="text-md my-4">To become a marketer for WRN, please <a href="/contact" className="underline font-medium">contact us</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What are the commission amount on WRN?" triggerTagName="div">
                <p className="text-md my-4">Please contact us for the commission amount to be earned on WRN.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Who can earn commissions on WRN?" triggerTagName="div">
                <p className="text-md my-4">Anybody can earn commissions on WRN via referrals.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commissions;
