import img404 from "assets/empty-subscriptions.svg";

export default function Plans404() {
  return (
    <div className="mx-4 my-10 col-span-4">
      <img
        alt={"no-plans-found"}
        className="mx-auto"
        src={img404}
        style={{ maxWidth: "350px" }}
      />
      <div className="flex justify-center">
        <span className="mt-8 py-2 px-10 custom-bg-green text-white font-semibold rounded-lg">
          No Plans Available
        </span>
      </div>
    </div>
  );
}