import gql from "graphql-tag";

export const GET_JUDGEMENT_LIST = gql`
  query judgements_list(
    $court_category: Int!
    $options: read_options
    $region: Region!
  ) {
    judgements_list(
      court_category: $court_category
      region: $region
      options: $options
    ) {
      ... on T_response {
        ok
        message
        status
        error {
          path
          message
        }
      }
      ... on judgements_list {
        ok
        message
        status
        total_count
        data {
          case_id
          case_no
          party_1
          party_2
          title
          section
          description
          date
          formatted_date
        }
      }
    }
  }
`;

export const GET_CASES = gql`
  query auth_case_details($case_id: Int!, $region: Region!) {
    case_details(case_id: $case_id, region: $region) {
      ... on T_response {
        ok
        message
        status
        error {
          path
          message
        }
      }
      ... on case_summary {
        ok
        message
        status
        data {
          case_details {
            id
            party_1
            party_2
            title
            volume
            section
            court_id
            case_no
            held
            party_1_desc
            party_2_desc
            facts_and_history
            issues
            cases_reference
            casejudgment
            case_history
            date
          }
          judgement_and_justices {
            judgement_id
            judgement
            judgement_type
            judgement_type_id
            justice
            justice_title
          }
          subject_matter {
            id
            subject_matter
            description
          }
          head_notes_and_ratio_decendis {
            hn_id
            rd_id
            heading
            summary
            link_per
          }
        }
      }
    }
  }
`;
