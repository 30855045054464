import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import "../index.css";

export default function WebLayout({ children }) {
  return (
    <React.Fragment>
      <Navbar />
      {children}
      <Footer />
    </React.Fragment>
  );
}
