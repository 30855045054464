import React, { createContext } from "react";
import { useMutation } from "@apollo/client";
import NEW_CONTACT_REQUEST from "schema/contact";
export const ContactsContext = createContext({});

export default function ContactsContextProvider({ children }) {
  const [ncr, { loading, data }] = useMutation(NEW_CONTACT_REQUEST);

  return (
    <ContactsContext.Provider value={{ ncr, loading, data }}>
      {children}
    </ContactsContext.Provider>
  );
}
