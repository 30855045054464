import React from "react";
import icons from "../../assets";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 13L17.17 13L13.59 16.59L15 18L21 12L15 6L13.59 7.41L17.17 11L3 11V13Z" fill="black"/>
      </svg>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 11H6.83L10.41 7.41L9 6L3 12L9 18L10.41 16.59L6.83 13H21V11Z" fill="black"/>
     </svg>
    </div>
  );
}

export default function Bottom() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
  };
  return (
    <React.Fragment>
      <div className="mx-20 my-16 m-x-8 rounded-lg bg-gray-100">
        <div className="block text-center py-10">
          <p className="custom-text-green custom-left-width inline p-2 text-center mx-auto text-xl my-6 font-bold">
            How it Works
          </p>
        </div>
        <div className="flex items-center blo-ck">
          <div className="w-1/2 full-width">
            <img alt={"image"} src={icons.itw} />
          </div>
          <div className="p-4 ml-20 ml-reset -mx-2 full-width">
            <Link to="https://test-dashboard.weeklyreportsofnigeria.com/" className="my-8 ml-reset blo-ck full-width mx-2 flex p-4 bg-white rounded-lg">
              <img className="-ml-12 ml-reset my-4" src={icons.icon1} />
              <div className="ml-4 ml-reset">
                <p className="font-bold custom-text-green">
                  Regularly Updated Report
                </p>
                <p className="max-w-xs my-2">
                  We have very experienced hands working to deliver judgments to
                  you on a regular basis.
                </p>
              </div>
            </Link>
            <Link to="/register" className="my-8 ml-reset blo-ck full-width flex mx-2 p-4 bg-white rounded-lg">
              <img className="-ml-12 ml-reset my-4" src={icons.icon2} />
              <div className="ml-4 ml-reset">
                <p className="font-bold custom-mid-green">Free Registration</p>
                <p className="max-w-xs my-2">
                  Every new member on our platform gets a month free access to
                  our updated and detailed law reports.
                </p>
              </div>
            </Link>
            <Link to="/features" className="my-8 ml-reset blo-ck full-width flex mx-2 p-4 bg-white rounded-lg">
              <img className="-ml-12 ml-reset my-4" src={icons.icon3} />
              <div className="ml-4 ml-reset">
                <p className="font-bold custom-text-yellow">
                  Affordable price of N2500
                </p>
                <p className="max-w-xs my-2">
                  With 2,500 you get access to our premium collection of law
                  reports and judgments.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-20 my-16 m-x-8 rounded-lg bg-white">
        <p className="custom-text-green p-2 text-center text-xl py-8 font-bold">
          We have Solutions for:
        </p>
        <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 grid gap-4">
          <div className="border rounded-lg bg-white">
            <Link to="/features">
              <img
                alt={"image"}
                src="https://res.cloudinary.com/ategsoft-technologies/image/upload/v1599581888/Homepage/375x200_WRN_Lawyer.png"
                className="rounded-lg w-full exvn"
              />
            </Link>
            <div className="p-4 my-2">
              <p className="font-bold text-xl custom-text-green">Lawyers</p>
              <p className="my-4 text-sm text-black">
                Offline Lifetime Version, Web and Mobile Edition for Lawyers.
              </p>
              <Link to="/features" className="custom-text-green font-bold">Explore</Link>
            </div>
            <div className="bottom-0 p-2 rounded-b-lg custom-bg-green"></div>
          </div>
          <div className="border rounded-lg bg-white">
            <Link to="/features">
              <img
                alt={"image"}
                src="https://res.cloudinary.com/ategsoft-technologies/image/upload/v1599581867/Homepage/375x200_WRN_Corporate.png"
                className="rounded-lg w-full exvn"
              />
            </Link>
            <div className="p-4 my-2">
              <p className="font-bold text-xl text-green-800">Corporate</p>
              <p className="my-4 text-sm text-black">
                Discounted Enterprise Edition for multiple users.
              </p>
              <Link to="/features" className="text-green-800 font-bold">Explore</Link>
            </div>
            <div className="bottom-0 p-2 rounded-b-lg bg-green-800"></div>
          </div>
          <div className="border rounded-lg bg-white">
            <Link to="/features">
              <img
                alt={"image"}
                src="https://res.cloudinary.com/ategsoft-technologies/image/upload/v1599581857/Homepage/375x200_Students.png"
                className="rounded-lg w-full exvn"
              />
            </Link>
            <div className="p-4 my-2">
              <p className="font-bold text-xl text-yellow-700">Law Student</p>
              <p className="my-4 text-sm text-black">
                Extremely discounted version tailored to University Students.
              </p>
              <Link to="/features" className="text-yellow-700 font-bold">Explore</Link>
            </div>
            <div className="bottom-0 p-2 rounded-b-lg bg-yellow-700"></div>
          </div>
          <div className="border rounded-lg bg-white">
            <Link to="/features">
              <img
                alt={"image"}
                src="https://res.cloudinary.com/ategsoft-technologies/image/upload/v1599581857/Homepage/375x200_Law_School.png"
                className="rounded-lg w-full exvn"
              />
            </Link>
            <div className="p-4 my-2">
              <p className="font-bold text-xl custom-text-yellow">Law School</p>
              <p className="my-4 text-sm text-black">
                Law School students get the most with our law school edition.
              </p>
              <Link to="/features" className="custom-text-yellow font-bold">Explore</Link>
            </div>
            <div className="bottom-0 p-2 rounded-b-lg custom-bg-yellow"></div>
          </div>
        </div>
      </div>
      <div className="mx-8 my-16 bg-white">
        <div className="block text-center my-4">
          <p className="custom-text-green custom-left-width inline p-2 text-center mx-auto text-xl my-6 font-bold">
            Testimonial
          </p>
        </div>
        <p className="text-black text-md text-center text-lg py-4 font-bold">
          Special Quotes from our customer about us.
        </p>
        <div className="mx-auto" style={{ maxWidth: '800px' }}>
          <Slider {...settings} className="slider">
            <div className="mx-auto my-20 p-6 s-content">
              <p className="max-w-sm text-black mx-auto text-center mb-10">
                We have been making use of Weekly Reports of Nigeria for a while. This is a good improvement to the old method of law reports .
              </p>
              <p className="custom-text-green mb-2 mt-4 font-bold text-center">
                Adisa Sule
              </p>
              <p className="text-bold mb-4 text-center font-bold">Subscriber</p>
            </div>
            <div className="mx-auto my-20 p-6 s-content">
              <p className="max-w-sm text-black mx-auto text-center mb-10">
                The management of Weekly Reports of Nigeria have done an amazing job in ensuring that the quality of the law reporting stays world-class. Impressive work!
              </p>
              <p className="custom-text-green mb-2 mt-4 font-bold text-center">
                Thomas Adio
              </p>
              <p className="text-bold mb-4 text-center font-bold">Subscriber</p>
            </div>
            <div className="mx-auto my-20 p-6 s-content">
              <p className="max-w-sm text-black mx-auto text-center mb-10">
                The Weekly Reports of Nigeria provide some of the most detailed law reporting in Nigeria. I am a satisfied customer.
              </p>
              <p className="custom-text-green mb-2 mt-4 font-bold text-center">
                Kelechi Munagwo
              </p>
              <p className="text-bold mb-4 text-center font-bold">Subscriber</p>
            </div>
            <div className="mx-auto my-20 p-6 s-content">
              <p className="max-w-sm text-black mx-auto text-center mb-10">
                We are proud to be associated with Weekly Reports of Nigeria over the years.
              </p>
              <p className="custom-text-green mb-2 mt-4 font-bold text-center">
                Barrister Sunday Eleme
              </p>
              <p className="text-bold mb-4 text-center font-bold">Lawyer</p>
            </div>
          </Slider>
        </div>
      </div>
      <div className="mx-20 my-16 m-x-8 flex blo-ck">
        <div className="w-1/2 p-8 full-width dsp">
          <p className="text-2xl custom-text-green font-bold my-8">
            Mobile App made simple
          </p>
          <p className="text-black text-xl max-w-sm font-light">
            Download our mobile app from Google Play for android and App Store
            for IOS devices.
          </p>
          <div className="my-6">
            <div className="w-40">
              <img alt={"image"} src={icons.appstore} />
            </div>
            <div className="mt-5 w-40">
              <img alt={"image"} src={icons.playstore} />
            </div>
          </div>
        </div>
        <div className="w-1/2 p-8 full-width bg-gray-100">
          <p className="text-2xl custom-text-green font-semibold my-8">
            Subscribe to WRN Articles
          </p>
          <div>
            <form className="max-w-md flex blo-ck">
              <input
                type="email"
                className="w-full rounded-lg py-4 px-4 border-2 border-yellow-600"
                placeholder="enter your email address"
              />
              <button className="py-4 px-6 sub-but custom-bg-green rounded-r-lg rounded-l-none -ml-2 text-white font-bold">
                Subscribe
              </button>
            </form>
          </div>
          <div className="shadow bg-white rounded-lg w-full p-2 my-6 flex blo-ck items-center">
            <div>
              <img alt={"image"} src={icons.sec} />
            </div>
            <div className="ml-4">
              <p className="text-xl text-black font-semibold">
                WRN is available online
              </p>
              <p className="text-black font-medium my-4">
                Have access to WRN anywhere you are
              </p>
              <a className="text-2xl my-4 custom-text-green font-semibold flex items-center none no-ne n-one">
                Download{" "}
                <img className="ml-2" src={icons.down} alt={"download"} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
