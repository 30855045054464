import React from "react";
import { Link } from "react-router-dom";
import { loginSchema } from "utils/validation";
import { useFormik } from "formik";
import { AuthContext } from "context/auth";
import { ButtonLoader } from "components/Loaders";
import styled from "styled-components";
import Input from "components/form/Input";
import logo from "assets/img/logo.svg";
import law from "assets/img/law.jpg";
import "../../assets/css/login.css";
import history from "../../utils/history";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const Container = styled.div`
  max-width: 670px;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: white;
  border-radius: 10px;
  @media (max-width: 960px) {
    display: block;
  }
`;

const Content = styled.div`
  padding: 50px;

  h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  & > p {
    font-size: 0.95rem;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    display: block;
  }
`;

const TextWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
`;
const Img = styled.img`
  display: block;
  margin-bottom: 30px;
`;
const ImgStyle = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @media (max-width: 960px) {
    display: none;
  }
`;

export default function Login() {
  const r = new URLSearchParams(history.location.search).get("role");
  const plan = new URLSearchParams(history.location.search).get("plan_code");
  const spc = new URLSearchParams(history.location.search).get("spc");

  const { loadingLogin, login } = React.useContext(AuthContext);
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      user_name: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit() {
      login({
        variables: {
          ...values,
          location: "user-board",
        },
      });
    },
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <Img src={logo} alt="logo" />
          <h3>Welcome Back!</h3>
          <p>Please login your account as:</p>
          <form className="auth-form" onSubmit={handleSubmit}>
            <Input
              handleChange={handleChange}
              name="user_name"
              id="user_name"
              placeholder=""
              label="Email"
              error={errors?.user_name}
              className=""
            />

            <Input
              handleChange={handleChange}
              type="password"
              name="password"
              id="password"
              placeholder=""
              label="Password"
              error={errors?.password}
            />

            <button disabled={loadingLogin}>
              {loadingLogin ? <ButtonLoader /> : "Login"}
            </button>
          </form>
          <TextWrapper>
            <Link to="/forgot-password">Forgot Password?</Link>
          </TextWrapper>

          <Link
            className="auth-signup"
            to={
              plan && !plan.length
                ? "/register"
                : `/register?plan_code=${plan}&role=${r}&spc=${spc}` // use redirectUrl =>
            }
          >
            Don't have account? Sign Up
          </Link>
        </Content>
        <ImgStyle src={law} />
      </Container>
    </Wrapper>
  );
}
