import React from "react";
import Top from "./Top";
import Bottom from "./Bottom";
import MiddleSection from "./MidSection";
import Features from "pages/Features/Features";

export default function Home({ history }) {
  return (
    <div className="content">
      <Top />
      <MiddleSection history={history} />
      <Features title={"WRN SUBSCRIPTION PLANS"} showFaq={false} />
      <Bottom />
    </div>
  );
}
