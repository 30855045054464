import React from "react";
import Routes from "routes/Routes";
import AppContextProvider from "context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import introspectionQueryResultData from "./fragmentTypes";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { setContext } from "@apollo/client/link/context";
import "react-toastify/dist/ReactToastify.css";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_ + "/graphql", // '',
  credentials: "include",
  headers: {
    "keep-alive": "true",
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_WS + "/graphql", // ``,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  link,
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <Routes />
      </AppContextProvider>
    </ApolloProvider>
  );
};

export default App;
