import React, { useContext } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import {
  SEARCH_EXTRA_RATIOS,
  SEARCH_EXTRA_SUBJECT_MATTER,
} from "schema/searches";
import { AuthContext } from "context/auth";
import { openSummaryIfNoAuthentication_GoToDashboardIfAuth } from "utils/summary";
import stripHtml, { subject_matter_formatter } from "utils/strip-html";

const SearchCase = styled.li`
  background: #f6f6f6;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;

  h4 {
    font-size: 1.05rem;
    margin-bottom: 25px;
    font-weight: 600;
    color: #27750a;

    span {
      color: #000;
      font-weight: 500;
    }
  }

  h5 {
    color: #27750a;
    font-size: 1.05rem;
    background: white;
    padding: 15px 25px;
    font-weight: 400;
    margin-bottom: 15px;
    border-radius: 5px;
    font-weight: 500;
  }

  p {
    margin-bottom: 15px;
    font-size: 0.95rem;
    color: #333333;
  }
`;

function SubjectMatter({ case_id, region }) {
  const { data, loading } = useQuery(SEARCH_EXTRA_SUBJECT_MATTER, {
    variables: { case_id, region },
  });

  return (
    <React.Fragment>
      <h5>Subject Matter</h5>
      {!loading && (
        <p
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {subject_matter_formatter(
            stripHtml(data?.se_subject_matter[0].description, ["strong"]).split(
              "\n"
            )
          ).join("\n")}
          <br /> <br />
          <span style={{ color: "red" }}>... Read More</span>
        </p>
      )}
    </React.Fragment>
  );
}

function RatioDecidendi({ case_id, region }) {
  const { data, loading } = useQuery(SEARCH_EXTRA_RATIOS, {
    variables: { case_id, region },
  });

  return (
    <React.Fragment>
      <h5>Ratio Decidendi</h5>
      {!loading && (
        <p
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {`${stripHtml(data?.se_ratios[0].summary).substring(0, 300)} .... `}
          <span
            style={{ color: "red" }}
          >{`${data?.se_ratios[0].link_per}  `}</span>
          <span style={{ color: "red" }}>[Read in context]</span>
        </p>
      )}
    </React.Fragment>
  );
}

export default function CasesTab({ data, history, region }) {
  const token = localStorage.getItem("wrn_user_dashboard_token") || "";
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <React.Fragment>
      {data?.length ? (
        <div className="card-body">
          <ul>
            {data.map((cas, i) => {
              const striped = stripHtml(cas.facts_and_history);
              return (
                <SearchCase
                  key={i}
                  onClick={() =>
                    openSummaryIfNoAuthentication_GoToDashboardIfAuth(
                      history,
                      isAuthenticated,
                      token,
                      cas.court_id,
                      cas.case_id
                    )
                  }
                >
                  <h4>
                    {cas.party_1} v. {cas.party_2} <br />
                    <span>{cas.volume}</span>
                  </h4>

                  <SubjectMatter case_id={cas.case_id} region={region} />

                  <RatioDecidendi case_id={cas.case_id} region={region} />

                  <h5>Fact & History</h5>
                  <p>
                    {striped.length <= 200
                      ? striped
                      : striped.substring(0, 200)}
                    <span style={{ color: "red" }}>... Read More</span>
                  </p>
                </SearchCase>
              );
            })}
          </ul>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
}
