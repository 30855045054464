import gql from "graphql-tag";

export const web_display = gql`
  query wd_blogs($options: read_options) {
    blogs(options: $options) {
      data {
        title
        id
        author {
          first_name
          last_name
        }
        created_at
      }
      total_count
    }
  }
`;

export const featured_blogs = gql`
  query featured_blogs($options: read_options) {
    featured_blogs(options: $options) {
      data {
        id
        title
        created_at
        updated_at
        category {
          category
          details
          thumbnail
        }
      }
      total_count
    }
  }
`;

export const blogs = gql`
  query blogs($options: read_options) {
    blogs(options: $options) {
      data {
        author {
          email
          first_name
          info
          last_name
          middle_name
          mobile
          photo
          suffix
          titles
        }
        category {
          category
          details
          thumbnail
        }
        comments {
          comment
          created_at
          id
          updated_at
        }
        content
        created_at
        id
        image {
          alt
          image
        }
        tags
        thumbnail
        title
        updated_at
      }
      total_count
    }
  }
`;

export const blog = gql`
  query blog($id: Int!) {
    blog(id: $id) {
      author {
        email
        first_name
        info
        last_name
        middle_name
        mobile
        photo
        suffix
        titles
      }
      category {
        category
        details
        thumbnail
      }
      comments {
        comment
        created_at
        id
        updated_at
      }
      content
      created_at
      id
      image {
        alt
        image
      }
      tags
      thumbnail
      title
      updated_at
    }
  }
`;
