import React from "react";
import third from "assets/third.png";
import pic from "assets/pic.svg";
import ate from "assets/ate.png";
import { Link } from "react-router-dom";

function OurTeam() {
  return (
    <div className="content">
      <div className="bg-gray-100 mx-8 blo-ck flex my-8">
        <div className="about-head p-10 full-width w-1/2 none">
          <div className="my-20">
            <p className="text-white font-bold text-2xl my-4">About WRN</p>
            <div className="my-10">
              <p className="text-white font-semibold text-xl">Best Value</p>
              <p className="text-white font-semibold text-xl my-2 ar-text">
                Legal Research
              </p>
              <p className="text-white font-semibold text-xl">Platform</p>
            </div>
          </div>
        </div>
        <div className="w-1/2 full-width p-10 none">
          <img className="mx-auto" src={third} />
        </div>
      </div>
      <div className="flex items-center border-b border-gray-500 mx-8 about-link">
        <Link
          to="/about"
          className="py-3 px-5 text-gray-600 font-semibold text-xl"
        >
          About WRN
        </Link>
        <Link
          to="/about/team"
          className="py-3 px-5 text-black border-b-2 border-yellow-500 font-semibold text-xl"
        >
          Our Team
        </Link>
        <a className="py-3 px-5 text-gray-600 font-semibold text-xl">
          Our Story
        </a>
        <a className="py-3 px-5 text-gray-600 font-semibold text-xl">
          WRN Advantage
        </a>
      </div>
      <div className="my-16 mx-8">
        <p className="custom-text-green text-2xl text-center font-bold my-6">
          Meet WRN Team
        </p>
        <div className="my-16">
          <p className="custom-text-green text-lg font-bold my-6">
            Management Team
          </p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4 my-10">
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Femi Falana SAN</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Chairman</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Funmi Falana</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Managing Director</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Ganiu Adenaike</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">General Manager</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-16">
          <p className="custom-text-green text-lg font-bold my-6">
            Editorial Team
          </p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4 my-10">
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Femi Falana SAN</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Editor-in-Chief</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Funmi Falana</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Managing Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Wahab Kunle Shittu</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Abu Debbie Glory (Mrs)</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Ehinomen Ojemen (Miss)</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Ledum Mitee</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">A. O. Mohammed</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Sina Oke</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Festus Okoye</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Ola Bada</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Olu Daramola SAN</p>
                <hr className="border-gray-300 border my-2" />
                <p className="font-medium ml-4 text-gray-800">Associate Editor</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-16">
          <p className="custom-text-green text-lg font-bold my-6">
            Technology Partners
          </p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4 my-10">
            <div className="p-4">
              <div>
                <img className="" src={ate} />
              </div>
            </div>
          </div>
        </div>
        <div className="my-16">
          <p className="custom-text-green text-lg font-bold my-6">
            Editorial Consultants
          </p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4 my-10">
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Konyin Ajayi SAN</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Wale Babalakin SAN</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Bolaji Owasanye</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Gbolahan Elias SAN</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Ayo Atsenuwa (Mrs)</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Dele Adesina SAN</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Tunde Fagbohunlu SAN</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Tunde Ayeni</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-16">
          <p className="custom-text-green text-lg font-bold my-6">
            Computer Secretaries
          </p>
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 grid gap-4 my-10">
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Abimbola Dadeowo (Mrs)</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Mercy C. Isaac-Yusuf (Mrs)</p>
              </div>
            </div>
            <div className="bg-gray-100 p-4 flex items-center">
              <div>
                <img src={pic} />
              </div>
              <div className="mx-4 w-full ml-r-eset">
                <p className="text-xl ml-4 font-bold">Nofisat Kazeem (Mrs)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
