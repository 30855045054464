import * as yup from "yup";

const passwordNotLongEnough = "password must be at least 8 characters";

export const loginSchema = yup.object({
  user_name: yup
    .string()
    .email("Please provide a valid email.")
    .required("Email is required."),
  password: yup.string().required("Password is required."),
});

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please provide a valid email.")
    .required("Email is required."),
});

const pv = yup.string().min(8, passwordNotLongEnough).required();

export const registerSchema = yup.object({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  phone_number: yup.string().required("Mobile number is required"),
  email: yup
    .string()
    .email("Please provide a valid email.")
    .required("Email is required."),
  role: yup.string().required("Role is required."),
  password: pv,
  contactAddress: yup.string().required("Contact Address is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  refferal_code: yup.string(),
});
