import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function BloArch() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Blog & Archives</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Where can I read blogs and Articles?" triggerTagName="div">
                <p className="text-md my-4">Our blogs and articles are available on our website at <a href="https://weeklyreportsofnigeria.com" className="underline font-medium">https://weeklyreportsofnigeria.com</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What kind of articles are on WRN?" triggerTagName="div">
                <p className="text-md my-4">Our articles are from a wide range of topics. Please, visit our articles page to find out all the categories.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Are the articles free?" triggerTagName="div">
                <p className="text-md my-4">Yes, all our articles are free for consumption.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I contribute to the articles?" triggerTagName="div">
                <p className="text-md my-4">Becoming a contributor with WRN is as simple as contacting us.</p>
                <p className="text-md my-4">Send us a message today, stating your intention and which categories you would like to contribute to. In your message, state the following: Name, email, phone number, Chamber / Work Place.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="I would like to report an article" triggerTagName="div">
                <p className="text-md my-4">Our articles go through some editing by our team. However, all the content are the property of the authors. To report an article, please contact us with the Title of the article and state what you would like to report.</p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BloArch;
