import React, { createContext, useEffect, useState } from "react";
import notify from "utils/toast";
import GET_SUBSCRIPTION_PLANS, {
  PURCHASE_SUBSCRIPTION,
} from "schema/subscription-plans";
import { useLazyQuery } from "@apollo/client";
import { goToPath } from "../auth";
export const SubPlansContext = createContext({});

export default function SubPlansContextProvider({ children }) {
  const [getSubs, { loading, data }] = useLazyQuery(GET_SUBSCRIPTION_PLANS);

  const [
    purchaseSubscription,
    { loading: psLoading, data: psData },
  ] = useLazyQuery(PURCHASE_SUBSCRIPTION);

  useEffect(() => {
    if (psData) {
      const {
        purchase_subscription: { data, error },
      } = psData;
      if (error) {
        error.forEach((err) => {
          notify(err.message, "error");
        });

        setTimeout(() => goToPath("/"), 2000);
      } else window.open(`${data.authorization_url}`, "_self"); // redirect to provider
    }
  }, [psData]);

  return (
    <SubPlansContext.Provider
      value={{ getSubs, loading, data, purchaseSubscription, psLoading }}
    >
      {children}
    </SubPlansContext.Provider>
  );
}
