import React from "react";
import search from "assets/search.svg";
import Collapsible from 'react-collapsible';
import { Link } from "react-router-dom";
import arreft from "assets/arr-eft.svg";

function SubBill() {
  return (
    <div className="content">
      <div className="my-16 mx-8">
      <div className="mb-8">
        <Link to='/support' className="flex items-center text-md font-semibold custom-text-green"> 
          <img style={{ marginRight: '5px' }} src={arreft} />
          Back To Support
        </Link>
      </div>
      <p className="custom-text-green text-2xl font-semibold mt-8 mb-4">Subscription & Billing</p>
        <div className="-mx-4">
          <div className="grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 grid gap-4">
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How do I subscribe for WRN?" triggerTagName="div">
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Click here</a> to view all the subscription categories for WRN.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I renew my subscription?" triggerTagName="div">
                <p className="text-md my-4">To renew subscription, please log in and go to account settings, and click renew account.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How do I subscribe for free?" triggerTagName="div">
                <p className="text-md my-4">You can register to use WRN for free if you have not previously registered. New users who wish to use WRN for free, are automatically given a one month free period to access WRN.</p>
                <p className="text-md my-4">To get this, please, click on the FREE TRIAL link on the homepage.</p>
                <p className="text-md my-4"><a href="https://weeklyreportsofnigeria.com/register" className="underline font-medium">Register for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Where can I check my subscription status?" triggerTagName="div">
                <p className="text-md my-4">You can check your account status from your Account Settings, after you have logged into WRN</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="What are the different subscription categories?" triggerTagName="div">
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Click here</a> to view all the subscription categories for WRN.</p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="Which subscription package is best for me?" triggerTagName="div">
                <p className="text-md my-4">See all the available <a href="/features" className="underline font-medium"> WRN versions</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I save money on subscription?" triggerTagName="div">
                <p className="text-md my-4">The best way to save money on subscription is by subscribing for a year. All yearly subscriptions have discounts as much as 45%. Take advantage of this offer today.</p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium"> Subscribe for WRN</a></p>
              </Collapsible>
            </div>
            <div className="bg-white rounded-lg p-4">
              <Collapsible trigger="How can I have my subscription autorenew?" triggerTagName="div">
                <p className="text-md my-4">Autorenewal   is   set   by   default.   To   deactivate   autorenewal,   please disable automatic payments in the account settings, or contact <a href="/support" className="underline font-medium">WRN support</a></p>
                <p className="text-md my-4">If you disable automatic renewal, you would have to initiate payment when your subscription expires. You would not be able to access WRN features such as reading judgement. To prevent this, please leave autorenewal active.</p>
                <p className="text-md my-4"><a href="/features" className="underline font-medium">Renew subscription</a></p>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubBill;
