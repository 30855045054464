import React, { useState, useContext, useEffect } from "react";
import FeaturesTab from "components/FeaturesTab";
import SubscriptionPlans from "./SubscriptionPlans";
import FeaturesBottom from "./Bottom";
import { SubPlansContext } from "context/subscription-plans";
import { BlockLoader } from "components/Loaders";
import SubscriptionPlanClass from "./SubscriptionPlanClass";
import { AuthContext, goToPath } from "context/auth";
import notify from "utils/toast";
import history from "utils/history";

function goToAvailableUserPlans(me_role) {
  if ([2, 3, 5].includes(me_role)) goToPath(`/features?role=${me_role}`);
  else {
    notify("Administrator accounts cannot purchase subscription", "info");
    goToPath("/");
  }
}

export default function Features({ title, showFaq = true }) {
  const plan_from_link = new URLSearchParams(history.location.search).get(
    "plan_code"
  );
  const r = new URLSearchParams(history.location.search).get("role");
  const spc_from_link = new URLSearchParams(history.location.search).get("spc");

  const [role, setRole] = useState(2); // |=> lawyer | law university | law school
  const [spc, setSpc] = useState(1); // |=> online | offline | enterprise
  const [filteredData, setFilteredData] = useState([]);
  const [dataBank, setDataBank] = useState([]);

  const {
    getSubs,
    loading,
    data,
    purchaseSubscription,
    psLoading,
  } = useContext(SubPlansContext);

  const { isAuthenticated, me } = useContext(AuthContext);

  useEffect(() => {
    if (r) {
      const role_from_link = parseInt(r);
      if (me.role !== role_from_link && filteredData.length) {
        notify("You don't have access to this plan", "error");
        // redirect to proper role plans
        setTimeout(() => goToAvailableUserPlans(me.role), 3000);
      } else {
        setRole(role_from_link);
        if (spc_from_link) setSpc(parseInt(spc_from_link));
        if (plan_from_link) {
          // purchase plan
          const _plan_ = filteredData.filter(
            (p) => p.plan_code === plan_from_link
          );
          if (_plan_.length) {
            purchaseSubscription({
              variables: {
                info: {
                  plan: plan_from_link,
                  region: "ng",
                  redirect_url: `${window.location.origin}/completed_subscription_purchase/${_plan_[0].subscription_plan_id}/${plan_from_link}`,
                },
                provider: _plan_[0].provider,
              },
            });
          }
        } else {
          notify(
            "An error occurred while selecting plan, please retry",
            "error"
          );
          goToAvailableUserPlans(me.role);
        }
      }
    }
  }, [plan_from_link, spc_from_link, r, filteredData]);

  useEffect(() => getSubs({ variables: { role } }), [role]);

  useEffect(
    () =>
      setFilteredData(
        dataBank.filter((plan) => plan.sub_plan_class.sub_plan_class_id === spc)
      ),
    [spc]
  );

  useEffect(() => {
    if (data?.getSubs && data?.getSubs.length) {
      setDataBank(data.getSubs);
      setFilteredData(
        data.getSubs.filter(
          (plan) => plan.sub_plan_class.sub_plan_class_id === spc
        )
      );
    } else setFilteredData([]);
  }, [data]);

  return (
    <div className="content">
      <div className="mx-8 my-8">
        <p className="font-bold text-2xl text-center custom-text-green">
          {title}
        </p>
        <p className="text-gray-600 text-lg mx-auto text-center my-4">
          Monthly, Quarterly and Yearly Subscription Pricing.
        </p>
      </div>
      <div className="mt-8 custom-bg-green-op flex items-center">
        <FeaturesTab setRole={setRole} role={role} />
      </div>
      <div className="border-b flex">
        <SubscriptionPlanClass spc={spc} setSpc={setSpc} />
      </div>
      {loading ? (
        <BlockLoader />
      ) : (
        <SubscriptionPlans
          plans={filteredData}
          purchaseSubscription={purchaseSubscription}
          isAuthenticated={isAuthenticated}
          psLoading={psLoading}
          role={role}
          spc={spc}
          plan_from_link={plan_from_link}
        />
      )}
      {showFaq && <FeaturesBottom />}
    </div>
  );
}
