import React from "react";
import PropTypes from "prop-types";

import HomeContext from "context/home";
import BlogContext from "context/blogs";
import StoreContext from "context/store";
import AuthContextProvider from "./auth";
import SearchContextProvider from "./search/index.js";
import ContactFormProvider from "./contacts";
import SubPlansProvider from "./subscription-plans";
import CaseContextProvider from "./Cases";

const AppContextProvider = ({ children }) => {
  return (
    <AuthContextProvider>
      <HomeContext>
        <BlogContext>
          <StoreContext>
            <SearchContextProvider>
              <ContactFormProvider>
                <SubPlansProvider>
                  <CaseContextProvider>{children}</CaseContextProvider>
                </SubPlansProvider>
              </ContactFormProvider>
            </SearchContextProvider>
          </StoreContext>
        </BlogContext>
      </HomeContext>
    </AuthContextProvider>
  );
};

export default AppContextProvider;

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
