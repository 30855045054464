import React from "react";
import styled from "styled-components";
import { GridEqual } from "components/Styles";

const CheckboxWrapper = styled.div`
  div {
    position: relative;
    height: 17px !important;
    width: 17px !important;
    margin-right: 10px;
    display: inline-block;
  }
  span {
    height: 17px !important;
    width: 17px !important;
    border: 1px solid #333333;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    height: 15px !important;
    width: 15px !important;
    &:checked + span {
      background: #27750a;
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const CheckBox = ({
  label,
  type = "radio",
  name,
  handleChange,
  error,
  options = [],
  disabled,
}) => {
  return (
    <Wrapper>
      <GridEqual gap="10px" count={4} isNotResponsive>
        <label>{label}</label>
        {options?.map((option, i) => (
          <CheckboxWrapper key={i}>
            <div
              style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            >
              {disabled && option?.defaultCheck ? (
                <input
                  type={type}
                  onChange={handleChange}
                  value={option?.value}
                  checked={option?.defaultCheck}
                />
              ) : (
                <input
                  type={type}
                  onChange={handleChange}
                  value={option?.value}
                  name={name}
                />
              )}
              <span />
            </div>
            <label>{option?.label}</label>
          </CheckboxWrapper>
        ))}
      </GridEqual>
      {error ? <p className="error">{error}</p> : ""}
    </Wrapper>
  );
};

export default CheckBox;
