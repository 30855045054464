import React, { createContext, useState, useEffect } from "react";
import {
  REGISTER_MUTATION,
  LOGIN_MUTATION,
  GET_USER,
  LOGOUT_MUTATION,
  FORGOT_PASSWORD,
} from "schema/auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import notify from "utils/toast";
import { isEmpty } from "lodash";
import history from "utils/history";
import { decodeToken } from "utils/auth";

export const AuthContext = createContext({});

export function goToPath(path) {
  history.push(path);
  window.location.reload();
}

function quickExit() {
  // exit without deleting server session & redirect
  localStorage.removeItem("wrn_user");
  localStorage.removeItem("wrn_user_dashboard_token");
  setTimeout(() => {
    goToPath("/");
  }, 1000);
}

export default function AuthContextProvider({ children }) {
  const user = localStorage?.wrn_user ? JSON.parse(localStorage?.wrn_user) : {};
  const [isAuthenticated, setIsAuthenticated] = useState(!isEmpty(user));
  const [me, setMe] = useState(user);
  const [login, { data: LoginData, loading: loadingLogin }] = useMutation(
    LOGIN_MUTATION
  ); // error
  const [meQuery, { data: meData, loading: meLoading }] = useLazyQuery(
    GET_USER
  );
  const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(
    LOGOUT_MUTATION
  );
  const [
    register,
    { loading: registerLoading, data: registerData },
  ] = useMutation(REGISTER_MUTATION);

  const [
    forgotPassword,
    { loading: forgotPasswordLoading, data: forgotPasswordData },
  ] = useLazyQuery(FORGOT_PASSWORD);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (registerData) {
      const {
        register: { error, status },
      } = registerData;
      if (error && error.length) {
        if (status === 500)
          notify("an error occurred please try again later 😞😞", "error");
        else setErrors(error);
      } else {
        // check for plan_code query
        const plan = new URLSearchParams(history.location.search).get(
          "plan_code"
        );
        const r = new URLSearchParams(history.location.search).get("role");
        const spc = new URLSearchParams(history.location.search).get("spc");

        notify("Welcome to WRN!!", "reg.success");
        if (plan && plan.length && r && r.length && spc && spc.length)
          setTimeout(
            () =>
              goToPath(
                `/login?redirectUrl=features&plan_code=${plan}&role=${r}&spc=${spc}`
              ),
            2000
          );
        else setTimeout(() => goToPath("/login"), 2000);
      }
    }
  }, [registerData]);

  useEffect(() => {
    if (LoginData) {
      const {
        login: { error, token },
      } = LoginData;
      if (error) {
        notify(error[0].message, "error");
      } else
        try {
          const token_data = decodeToken(token);
          meQuery();
          localStorage.setItem("wrn_user", JSON.stringify(token_data));
          localStorage.setItem("wrn_user_dashboard_token", token);
          notify("Welcome back 👋", "reg.success");
          setTimeout(() => {
            const redirectUrl = new URLSearchParams(
              history.location.search
            ).get("redirectUrl");

            // check for plan_code query
            const plan = new URLSearchParams(history.location.search).get(
              "plan_code"
            );
            const r = new URLSearchParams(history.location.search).get("role");
            const spc = new URLSearchParams(history.location.search).get("spc");

            if (plan && plan.length && r && r.length && spc && spc.length) {
              const path = `?plan_code=${plan}&role=${r}&spc=${spc}`;
              goToPath("/features" + path);
            } else if (redirectUrl) goToPath("/" + redirectUrl);
            else goToPath("/");
          }, 2000);
        } catch (e) {
          notify(
            "An unexpected error occurred, please try again later",
            "error"
          );
        }
    }
  }, [LoginData]);

  useEffect(() => {
    if (meData) {
      const {
        me: { error, data },
      } = meData;
      if (error) {
        localStorage.removeItem("wrn_user");
        localStorage.removeItem("wrn_user_dashboard_token");
        setIsAuthenticated(false);
      } else {
        let newData = { ...data, role: data.role.role_id, role_: data.role };

        if (data?.profile_pic && data?.profile_pic?.blob) {
          const b64 = new Buffer(data.profile_pic.blob.data).toString("base64");
          const imageUri = `data:${data.profile_pic.mimetype};base64,${b64}`;
          newData = { ...newData, profile_pic: imageUri };
        }
        if (data?.identification && data?.identification?.blob) {
          const b64 = new Buffer(data.identification.blob.data).toString(
            "base64"
          );
          const imageUri = `data:${data.identification.mimetype};base64,${b64}`;
          newData = { ...newData, identification: imageUri };
        }
        setMe(newData);
        localStorage.setItem("wrn_user", JSON.stringify(newData));
      }
    }
  }, [meData]);

  useEffect(() => {
    if (logoutData) {
      notify("bye 👋", "reg.success");
      quickExit();
    }
  }, [logoutData]);

  //Send Email forgotten password
  useEffect(() => {
    if (forgotPasswordData) {
      const {
        sendForgotPasswordEmail: { error },
      } = forgotPasswordData;
      if (error) {
        notify(error[0].message, "error");
      } else {
        notify(
          "Hi, please check your email to complete password update ✨✨",
          "reg.success"
        );
      }
    }
  }, [forgotPasswordData]);

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        me,
        meQuery,
        user,
        login,
        forgotPasswordLoading,
        forgotPassword,
        loadingLogin,
        register,
        errors,
        logout,
        logoutLoading,
        meLoading,
        registerLoading,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

/*
 [subscribe to local_storage]:
   useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('userData')

      if (item) {
        setUserData(item)
      }
    }

    window.addEventListener('storage', checkUserData)

    return () => {
      window.removeEventListener('storage', checkUserData)
    }
  }, [])
 */
