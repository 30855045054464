import React from "react";
import img from "assets/joined.svg";
import twitter from "assets/twitter.svg";
import ig from "assets/ig.svg";
import fb from "assets/fb.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-gray-100">
      <div className="content px-10 py-10">
        <div className="flex blo-ck -mx-4">
          <div className="mx-4 ml-reset full-width reset-mx my-4 w-1/3">
            <p className="text-gray-800 text-xl font-semibold">WRN</p>
            <div className="mt-4">
              <p className="max-w-sm text-gray-700 text-md">
                Weekly Reports of Nigeria is Link collection of weekly law reports
                by Legaltext Publishing Company Limited. The reports are now
                being digitized to ensure that we serve our customers the very
                best.
              </p>
            </div>
            <div className="mt-4 none no-ne">
              <a href="https://ategsoft.net">
                <img src={img} />
              </a>
            </div>
          </div>
          <div className="mx-4 ml-reset my-4 full-width w-1/3">
            <p className="text-gray-800 text-xl font-semibold">Quick Links</p>
            <div className="mt-4 block">
              <div className="my-2">
                <Link to="/features" className="text-gray-700 text-md">
                  Features
                </Link>
              </div>
              <div className="my-2">
                <Link to="/support" className="text-gray-700 text-md">
                  Support
                </Link>
              </div>
              <div className="my-2">
                <Link to="/about" className="text-gray-700 text-md">
                  About
                </Link>
              </div>
              <div className="my-2">
                <Link to="/privacypolicy" className="text-gray-700 text-md">
                  Privacy Policy
                </Link>
              </div>
              <div className="my-2">
                <Link to="" className="text-gray-700 text-md">
                  Terms of Use
                </Link>
              </div>
              <div className="my-2">
                <Link to="/contact" className="text-gray-700 text-md">
                  Our Offices
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-4 ml-reset full-width my-4 w-1/3">
            <p className="text-gray-800 text-xl font-semibold">Address</p>
            <div className="mt-4">
              <p className="max-w-xs text-gray-700 text-md">
                LegalText Publishing Co. LTD 10, Evelyn Owuama Street River
                Valley Estate, Ojodu Berger, Lagos.
              </p>
            </div>
            <div className="mt-4">
              <p className="custom-text-green text-lg font-semibold">
                0700-WRN-0000, 0700-976-0000
              </p>
            </div>
            <div className="mt-4">
              <p className="custom-text-yellow mb-4 max-w-sm font-semibold text-md">
                info@weeklyreportsofnigeria.com
              </p>
            </div>
            <div className="mt-4">
              <div className="flex items-center">
                <a href="">
                  <img src={twitter} />
                </a>
                <a href="" className="mx-4">
                  <img src={ig} />
                </a>
                <a href="">
                  <img src={fb} />
                </a>
              </div>
              <div className="mt-8 hide-for-phone">
                <img src={img} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-bg-green p-4">
        <p className="text-white font-medium text-center">
          Copyright © 2000 - 2020. Legaltext Publishing Company Limited.
        </p>
      </div>
    </div>
  );
}

export default Footer;
