export default function yearsFunc(startYear) {
  const currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export function yearsFuncReverse(minYear) {
  let currentYear = new Date().getFullYear(),
    years = [];
  minYear = minYear || 1980;
  while (currentYear >= minYear) {
    years.push(currentYear--);
  }
  return years;
}
