import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "pages/Home";
import About from "pages/About";
import OurTeam from "pages/OurTeam";
import Store from "pages/Store";
import Item from "pages/Item";
import Cart from "pages/Cart";
import Contact from "pages/contacts/Contact";
import ContactUs from "pages/contacts/ContactUs";
import ContactSales from "pages/contacts/ContactSales";
import ContactTech from "pages/contacts/ContactTech";
import Features from "pages/Features/Features";
import TopFaqs from "pages/Support/TopFaqs";
import Acc from "pages/Support/AccSettings";
import Reg from "pages/Support/RegLog";
import Sub from "pages/Support/SubBill";
import Pay from "pages/Support/Payment";
import Comm from "pages/Support/Commissions";
import Orders from "pages/Support/Orders";
import Sto from "pages/Support/StoBooks";
import Ver from "pages/Support/Versions";
import Down from "pages/Support/Down";
import Work from "pages/Support/Work";
import Blog from "pages/Support/BloArch";
import Wpp from "pages/Wpp";
import Support from "pages/Support/Support";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error404 from "components/Error404";
import Summary from "pages/cases/Summary";
import Cases from "pages/cases/Cases";

import Article from "pages/articles/Article";

// Search-Result:
import SearchResult from "pages/Search";

// Authentication
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import ForgotPassword from "pages/auth/ForgotPassword";
import GoTo from "pages/auth/CompletedSubscriptionPurchase";

// Routes & Layout:
import AuthRoute from "./utils/AuthRoute";
import AuthLayout from "layouts/AuthLayout";
import WebRoute from "./utils/WebRoute";
import WebLayout from "layouts/WebLayout";
import PaymentCard from "components/modal/PaymentCard";

export default function Routes() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <AuthRoute
              exact
              path="/login"
              component={Login}
              layout={AuthLayout}
            />

            <AuthRoute
              exact
              path="/register"
              component={Register}
              layout={AuthLayout}
            />

            <AuthRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
              layout={AuthLayout}
            />

            <WebRoute exact path="/" component={Home} layout={WebLayout} />

            <WebRoute
              exact
              path="/completed_subscription_purchase/:id/:plan"
              component={GoTo}
              layout={WebLayout}
            />

            <WebRoute
              exact
              path="/search"
              component={SearchResult}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/about"
              component={About}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/about/team"
              component={OurTeam}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/store"
              component={Store}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/store/Item"
              component={Item}
              layout={WebLayout}
            />
            <WebRoute layout={WebLayout} exact path="/cart" component={Cart} />
            <WebRoute
              exact
              path="/contact"
              component={Contact}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/contact/us"
              component={ContactUs}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/contact/sales"
              component={ContactSales}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/contact/tech"
              component={ContactTech}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/features"
              component={() => <Features title={"WRN SUBSCRIPTION PLANS"} />}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support"
              component={Support}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/topfaqs"
              component={TopFaqs}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/accountsettings"
              component={Acc}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/registration"
              component={Reg}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/subscription"
              component={Sub}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/payments"
              component={Pay}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/commissions"
              component={Comm}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/orders"
              component={Orders}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/store"
              component={Sto}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/versions"
              component={Ver}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/downloads"
              component={Down}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/blog"
              component={Blog}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/support/workwithus"
              component={Work}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/privacypolicy"
              component={Wpp}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/judgement/:court"
              component={Cases}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/judgement/:court_id/:case_id"
              component={Summary}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/article"
              component={Article}
              layout={WebLayout}
            />
            <WebRoute
              exact
              path="/p-card"
              component={PaymentCard}
              layout={WebLayout}
            />
            {/* catch all invalid urls */}
            <WebRoute layout={WebLayout} component={Error404} />
          </Switch>
        </div>
      </BrowserRouter>
      <ToastContainer autoClose={3000} pauseOnHover={true} transition={Slide} />
    </React.Fragment>
  );
}
