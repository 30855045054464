import React from "react";
import { Link } from "react-router-dom";

export default function FeaturesBottom() {
  return (
    <React.Fragment>
      <div className="mx-8 my-16">
        <div className="flex blo-ck items-center">
          <div className="w-1/2 full-width">
            <p className="text-2xl font-bold custom-text-green max-w-sm">
              Frequently Asked Questions
            </p>
          </div>
          <div className="w-1/2 full-width">
            <div className="my-6">
              <p className="mb-2 text-xl font-bold custom-text-green">
                How can I renew my subscription?
              </p>
              <p className="text-gray-500">To renew subscription, please log in and go to account settings, and click renew account.</p>
            </div>
            <div className="my-6">
              <p className="mb-2 text-xl font-bold custom-text-green">
                How do I subscribe for free?
              </p>
              <p className="text-gray-500 mb-2">You can register to use WRN for free if you have not previously registered. New users who wish to use WRN for free, are automatically given a one month free period to access WRN.</p>
                <p className="text-gray-500 mb-2">To get this, please, click on the FREE TRIAL link on the homepage.</p>
                <p className="text-gray-500 mb-2"><a className="underline font-medium">Register for WRN</a></p>
            </div>
            <div className="my-6">
              <p className="mb-2 text-xl font-bold custom-text-green">
                Where can I check my subscription status?
              </p>
              <p className="text-gray-500">You can check your account status from your Account Settings, after you have logged into WRN</p>
            </div>
            <div className="my-6">
              <p className="mb-2 text-xl font-bold custom-text-green">
                What are the different subscription categories?
              </p>
              <p className="text-gray-500"><a className="underline font-medium">Click here</a> to view all the subscription categories for WRN.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-8 bg-orange-100 py-8">
        <p className="text-2xl font-bold custom-text-green text-center">
          Have more questions?
        </p>
        <p className="font-semibold text-black my-2 text-center">
          Don’t worry, we’ve got you covered.
        </p>
      </div>
      <div className="text-center pb-16">
        <Link
          to="/contact/sales"
          className="py-2 px-8 custom-bg-green rounded-lg text-white font-semibold text-lg"
        >
          Contact Sales
        </Link>
      </div>
    </React.Fragment>
  );
}